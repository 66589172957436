import React from 'react';
import loader from '../images/loading.svg'

const Loader = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <img src={loader} alt='loader' />
        </div>
    );
}

export default Loader;
