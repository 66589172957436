import React, { useEffect, useState } from "react";
import CheckMark from "../images/checkmark.svg";
import EnrollForm from "../components/EnrollForm";
// import useGoogleAnalytics from "../components/googleanalytics";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentAmt, setPaymentAmt] = useState(0);

  const openModal = (amt) => {
    setPaymentAmt(amt);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Initialize Google Analytics

  return (
    <>
      <div
        style={{ backgroundColor: "#e9ecef" }}
        className="font-extrabold flex items-center justify-center text-black text-center text-4xl gap-4 md:text-2xl p-4 uppercase"
      >
        Start Your fitness journey
      </div>
      <div className="shadow-md text-center m-2 mb-4 p-3 text-sm font-semibold text-gray-700">
        Note: <b>EMI</b> options are available for selected banks at the
        checkout page
      </div>
      <p></p>
      <EnrollForm amt={paymentAmt} isOpen={isModalOpen} onClose={closeModal} />
      <div className="flex m-4 z-50 justify-evenly items-center md:flex-wrap gap-5">
        <div
          id="single-transformation"
          className="w-96 p-5 relative bg-white rounded-lg shadow-lg"
          style={{ height: "49rem" }}
        >
          <div
            className="transform absolute rounded-lg -top-5 translate-y-1/2 left-0 h-5 bg-gradient-to-br from-indigo-400 to-purple-900 w-full"
            style={{ zIndex: "-10" }}
          />
          <p className="text-center text-xl uppercase font-medium">
            Single Transformation
          </p>
          <div className="flex gap-1 justify-evenly">
            <div className="mb-6 text-2xl text-center p-5 flex flex-col shadow-lg rounded-lg">
              <strong>
                ₹ <br />
                <div className="flex items-center justify-center gap-2 sm: flex-wrap">
                  <div style={{ fontSize: "20px" }}>9,999</div>
                  <div
                    style={{
                      fontSize: "10px",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      height: "16px",
                    }}
                    className="bg-gradient-to-br from-indigo-400 to-purple-900 text-white rounded-md flex items-center"
                  >
                    30% OFF
                  </div>
                </div>
                <span
                  style={{ color: "gray", fontSize: "20px" }}
                  className="line-through flex items-center justify-center"
                >
                  14,999
                </span>
              </strong>
              <small className="text-xs">for 3 months</small>
              <div className="flex justify-center items-center">
                <button
                  onClick={() => openModal(9999.0)}
                  className="m-2 w-20 text-sm bg-green-500 text-white p-2 rounded-lg font-extrabold hover:bg-black text-center"
                >
                  Enroll
                </button>
              </div>
            </div>
            <div className="mb-6 text-2xl text-center p-5 flex flex-col shadow-lg rounded-lg">
              <strong>
                ₹ <br />
                <div className="flex items-center justify-center gap-2 sm: flex-wrap">
                  <div style={{ fontSize: "20px" }}>15,999</div>
                  <div
                    style={{
                      fontSize: "10px",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      height: "16px",
                    }}
                    className="bg-gradient-to-br from-indigo-400 to-purple-900 text-white rounded-md flex items-center"
                  >
                    40% OFF
                  </div>
                </div>
                <span
                  style={{ color: "gray", fontSize: "20px" }}
                  className="line-through flex items-center justify-center"
                >
                  26,999
                </span>
              </strong>
              <small className="text-xs">for 6 months</small>
              <div className="flex justify-center items-center">
                <button
                  onClick={() => openModal(15999.0)}
                  className="m-2 w-20 text-sm bg-green-500 text-white p-2 rounded-lg font-extrabold hover:bg-black"
                >
                  Enroll
                </button>
              </div>
            </div>
          </div>
          <p className="mt-4 text-center font-bold">
            What's Included in the Plan?
          </p>
          <div className="p-6">
            <ol className="list-inside">
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Customized Workout Plan
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Personalized Nutrition Guidance
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Progress Tracking (Weekly/Bi-Weekly Check-ins)
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                1-to-1 Coaching support (Chats & Calls)
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Exercise form & techniques correction
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Plans are dynamic, modifying & adapting with time
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Answering all your questions about training and nutrition
              </li>
            </ol>
          </div>
        </div>
        <div
          id="partner-transformation"
          className="w-96 p-5 relative bg-white rounded-lg shadow-lg"
          style={{ height: "49rem" }}
        >
          <div
            className="transform absolute rounded-lg -top-5 translate-y-1/2 left-0 h-5 bg-gradient-to-tr from-red-100 to-red-500 w-full"
            style={{ zIndex: "-10" }}
          />
          <p className="text-center text-xl uppercase font-medium">
            Partner Transformation
          </p>
          <div className="flex gap-1 justify-evenly">
            <div className="mb-6 text-2xl text-center p-5 flex flex-col shadow-lg rounded-lg">
              <strong>
                ₹ <br />
                <div className="flex items-center justify-center gap-2 sm: flex-wrap">
                  <div style={{ fontSize: "20px" }}>16,999</div>
                  <div
                    style={{
                      fontSize: "10px",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      height: "16px",
                    }}
                    className="bg-gradient-to-br from-red-100 to-red-500 text-white rounded-md flex items-center"
                  >
                    30% OFF
                  </div>
                </div>
                <span
                  style={{ color: "gray", fontSize: "20px" }}
                  className="line-through flex items-center justify-center"
                >
                  24,999
                </span>
              </strong>
              <small className="text-xs">for 3 months</small>
              <div className="flex justify-center items-center">
                <button
                  onClick={() => openModal(16999.0)}
                  className="m-2 w-20 text-sm bg-green-500 text-white p-2 rounded-lg font-extrabold hover:bg-black"
                >
                  Enroll
                </button>
              </div>
            </div>
            <div className="mb-6 text-2xl text-center p-5 flex flex-col shadow-lg rounded-lg">
              <strong>
                ₹ <br />
                <div className="flex items-center justify-center gap-2 sm: flex-wrap">
                  <div style={{ fontSize: "20px" }}>28,999</div>
                  <div
                    style={{
                      fontSize: "10px",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      height: "16px",
                    }}
                    className="bg-gradient-to-br from-red-100 to-red-500 text-white rounded-md flex items-center"
                  >
                    40% OFF
                  </div>
                </div>
                <span
                  style={{ color: "gray", fontSize: "20px" }}
                  className="line-through flex items-center justify-center"
                >
                  47,500
                </span>
              </strong>
              <small className="text-xs">for 6 months</small>
              <div className="flex justify-center items-center">
                <button
                  onClick={() => openModal(28999.0)}
                  className="m-2 w-20 text-sm bg-green-500 text-white p-2 rounded-lg font-extrabold hover:bg-black"
                >
                  Enroll
                </button>
              </div>
            </div>
          </div>
          <p className="mt-4 text-center font-bold">
            What's Included in the Plan?
          </p>
          <div className="p-6">
            <ol className="list-inside">
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Tailored Partner Plans
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Customized Workout Plan
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Personalized Nutrition Guidance
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Progress Tracking (Weekly/Bi-Weekly Check-ins)
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                1-to-1 Coaching support (Chats & Calls)
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Exercise form & techniques correction
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Plans are dynamic, modifying & adapting with time
              </li>
              <li className="mb-4 flex items-center gap-2">
                <img
                  src={CheckMark}
                  className="h-5 w-5 inline"
                  alt="checkmark"
                />
                Answering all your questions about training and nutrition
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
