import React, { useEffect } from "react";
import DushyantImg from "../images/dushyant_profile.PNG";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { GrCertificate } from "react-icons/gr";
import { FaBriefcase } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { LiaCertificateSolid } from "react-icons/lia";
import { ServicesH1 } from "../components/Services/ServicesElements";
// import useGoogleAnalytics from "../components/googleanalytics";

const coaches = [
  {
    id: 1,
    name: "Dushyant Shahane",
    level: "Premium",
    slots: 4,
    image: DushyantImg,
    certificationsName: "CSCS | FSN | ESS | NFC",
    experience: "5+ years",
    trained: "Coached 600+",
    specialization: [
      "Fatloss",
      "Athlete fitness",
      "Body recomposition",
      "Improving health conditions",
      "General well being",
      "Senior citizen fitness",
      "Muscle gain",
    ],
  },
  {
    id: 2,
    name: "Rohit Shahane",
    level: "Premium",
    slots: 2,
    image: "/rohit_profile_1.jpg",
    certificationsName: "CNFC | ESS | EFC",
    experience: "5+ years",
    trained: "Coached 800+",
    specialization: [
      "Fatloss",
      "Body recomposition",
      "Improving health conditions",
      "General well being",
      "Senior citizen fitness",
      "Muscle gain",
    ],
  },
];

const Coaches = ({ isFromGoogleAdd = false }) => {
  const hoverClass = isFromGoogleAdd
    ? "shadow-lg transition-all ease-in-out duration-200 cursor-pointer hover:transform-gpu hover:scale-105 hover:transition-all hover:ease-in-out hover:duration-200"
    : "";

  const divStyle = !isFromGoogleAdd ? { height: "780px", width: "320px" } : {};

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const RedirectToCoach = (id) => {
    window.location.href = `/coach?coach=${id}`;
  };

  return (
    <>
      <div
        className={`flex flex-row md:flex-col justify-center items-center gap-2 my-2`}
      >
        {coaches.map((coach) => (
          <div
            style={divStyle}
            key={coach.id}
            className={`${
              isFromGoogleAdd
                ? "flex md:flex-wrap md:w-full items-center w-1/3 justify-center p-4 text-2xl"
                : ""
            }px-4 mb-8`}
          >
            <div
              className={`bg-white shadow-md rounded px-4 py-6 flex flex-col justify-between h-full  ${hoverClass}`}
            >
              <div
                onClick={isFromGoogleAdd && (() => RedirectToCoach(coach.id))}
              >
                <img
                  style={{ backgroundPosition: "center" }}
                  src={coach.image}
                  alt={coach.name}
                  className="w-full h-100 object-cover rounded mb-2"
                />
                <h2
                  className={`${
                    isFromGoogleAdd ? "text-4xl" : "text-lg"
                  } font-bold`}
                >
                  {coach.name}
                </h2>
                <span
                  style={{
                    background: "linear-gradient(to left top, blue, red)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bolder",
                  }}
                  className={`inline-block text-black py-1 rounded mb-2`}
                >
                  {coach.level}
                </span>
                <div className="flex items-center gap-2 mb-2">
                  <GrCertificate style={{ fontSize: "25px" }} />
                  {coach.certificationsName}
                </div>
                <div className="flex items-center gap-2 mb-2">
                  <FaBriefcase style={{ fontSize: "25px" }} />
                  {coach.experience}
                </div>
                <div className="flex items-center gap-2 mb-2">
                  <IoIosPeople style={{ fontSize: "25px" }} />
                  {coach.trained}
                </div>
                <div className="flex items-center gap-2 mb-2 border-b border-gray-700 pb-2">
                  <LiaCertificateSolid style={{ fontSize: "25px" }} />
                  <b>Specializations</b>
                </div>
                <div className="flex items-center mb-2 ml-5">
                  <ul style={{ listStyleType: "disc" }}>
                    {coach.specialization.map((specialization) => (
                      <li>{specialization}</li>
                    ))}
                  </ul>
                </div>
              </div>
              {!isFromGoogleAdd && (
                <Link
                  to={`/coach?coach=${coach.id}`}
                  className="bg-green-500 hover:bg-orange-700 text-black font-bold py-2 px-4 rounded text-center"
                >
                  View & Enroll
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const CoachesPage = ({ isFromGoogleAdd = false }) => {
  return (
    <div className="bg-gray-100">
      {!isFromGoogleAdd && (
        <header
          style={{ backgroundColor: "rgb(233, 236, 239)" }}
          className="py-6"
        >
          <h1 className="text-4xl uppercase font-bold text-center">Coaches</h1>
        </header>
      )}
      <main className="container mx-auto">
        <section className="text-center px-4 py-2 ">
          {isFromGoogleAdd ? (
            <ServicesH1>
              <p className="text-black font-bold py-2 px-4 rounded-3xl mt-4">
                Meet Our Expert Coaches
              </p>
            </ServicesH1>
          ) : (
            <h2 className="text-2xl font-semibold mb-4">
              Meet Our Expert Coaches
            </h2>
          )}
          <p className="text-gray-700">
            We are here to help you achieve your fitness goals. Whether you're
            looking to lose weight, build muscle, or improve your overall
            health, we have the right coach for you.
          </p>
        </section>
        <Coaches isFromGoogleAdd={isFromGoogleAdd} />
      </main>
    </div>
  );
};

export default CoachesPage;
