import React from "react";
import "../../node_modules/flag-icons/css/flag-icons.min.css";
import { FaStar } from "react-icons/fa";

const StatsComponent = () => {
  // Initialize Google Analytics

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="flex justify-around items-center text-center font-sans py-5 flex-wrap w-full"
    >
      <div className="m-2">
        <h2 className="text-4xl">5+</h2>
        <p>years of experience</p>
      </div>
      <div className="m-2">
        <h2 className="text-4xl">1.4k+</h2>
        <p>successful clients</p>
      </div>
      <div id="stats-flag" className="m-2 md:self-center md:w-full">
        <div
          style={{ fontSize: "25px" }}
          className="flex items-center justify-center gap-2"
        >
          <span class="fi fi-au"></span>
          <span class="fi fi-us"></span>
          <span class="fi fi-in"></span>
          <span class="fi fi-gb"></span>
          <span class="fi fi-nl"></span>
          <span class="fi fi-ae"></span>
        </div>
        <p>global presence</p>
      </div>
      <div className="m-2">
        <h2 className="text-4xl">5</h2>
        <p>Rating</p>
        <div className="flex justify-center">
          {[1, 2, 3, 4, 5].map(() => (
            <span style={{ color: "rgba(245, 158, 11)" }}>
              <FaStar />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsComponent;
