import trainer from "../../images/trainer.png";
import mealPhoto from "../../images/mealPhoto.png";
import enroll from "../../images/svg-3.svg";

export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About",
  headline:
    "Achieving Optimal Health Through Certified Nutrition and Fitness Consulting",
  description:
    "As a dedicated fitness online coaches, we combine our extensive knowledge of exercise science, nutrition, and personalized coaching to empower clients to reach their health and fitness goals. Our expertise lies in crafting tailored workout and nutrition plans, guiding clients through transformative journeys, and providing unwavering support.Our comprehensive mobile app also allows users to seamlessly track their activities, workouts, meals, and overall progress, ensuring they stay on top of their fitness journey anytime, anywhere.",
  buttonDisplay: true,
  buttonLabel: "Meet Our Coaches",
  reactScroll: false,
  buttonLink: "coaches",
  imgStart: false,
  img: trainer,
  alt: "Trainer",
  dark: true,
  primary: true,
  darkText: false,
  isMealSection: false,
  isPlanSection: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: "Client Meal Snaps",
  headline: "Delicious Moments Shared",
  reactScroll: false,
  description: "Delicious <br /> ultralicious <br /> great <br />",
  buttonLabel: "Meet Our Coaches",
  buttonDisplay: false,
  buttonLink: "coach",
  imgStart: true,
  img: mealPhoto,
  alt: "trainer",
  dark: false,
  primary: true,
  darkText: false,
  isMealSection: true,
  isPlanSection: false,
};

export const homeObjThree = {
  id: "enroll",
  buttonLink: "pricing",
  reactScroll: false,
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Join Us",
  headline: "Sculpt success with us on your fitness journey!",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Enroll Now",
  buttonDisplay: true,
  imgStart: false,
  img: enroll,
  alt: "Papers",
  dark: false,
  primary: false,
  darkText: true,
  isMealSection: false,
  isPlanSection: true,
};
