import React, { useState, useEffect } from "react";
import {
  ArrowForward,
  ArrowRight,
} from "../components/HeroSection/HeroElements";
import { Button } from "../components/ButtonElements";
import { useHistory } from "react-router-dom";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover((prev) => !prev);
  };

  // Initialize Google Analytics

  return (
    <>
      <h1
        style={{
          backgroundImage: "url('/disclaimer.jpeg')",
        }}
        className="bg-cover font-bold h-40 bg-no-repeat bg-center text-white flex justify-center p-5 text-6xl uppercase items-center tracking-wider text-center md:text-4xl"
      >
        Disclaimer
      </h1>
      <div className="m-5 p-3 border-r-2 shadow-md">
        The website/mobile application is offered as it is, available when you
        need it. By using our services, you understand that you're taking on any
        associated risks. To the fullest extent allowed by the law, we don't
        make any promises, whether implied or explicit, regarding the
        website/mobile application or your use of it. This includes but isn't
        limited to promises of merchantibility, fitness for a specific purpose,
        and non-infringement. We don't guarantee the accuracy or completeness of
        the website/mobile application's content or the content on linked
        websites. We won't be responsible for: - Any errors or inaccuracies in
        the content and materials. - Any harm or damage, of any kind, resulting
        from your use of the website. - Unauthorized access to our secure
        servers or the information stored there. - Interruptions in the
        website/mobile application's operation. - Any harmful elements like bugs
        or viruses that might be transmitted by third parties. - Errors or
        omissions in the content and materials, or any loss or damage incurred
        from using posted content. We don't vouch for, endorse, or take
        responsibility for any products or services offered by third parties
        through the website/mobile application or linked websites. We're not
        involved in or responsible for any transactions between you and
        third-party providers. As with any purchase, you should exercise caution
        and use your best judgment.
        <div className="m-4">
          <Button
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            onClick={() => history.push("/")}
          >
            Accept & Go Back {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
