import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./pages";
import Terms from "./pages/terms";
import Policy from "./pages/policy";
import Disclaimer from "./pages/disclaimer";
import Pricing from "./pages/pricing";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Contact from "./pages/contact";
import Coach from "./pages/coach";
import Footer from "./components/Footer";
import FAQ from "./pages/faq";
import Transformation from "./pages/transformation";
import WhatsAppButton from "./components/WhatsappButton";
import Coaches from "./pages/coaches";
import NotFound from "./components/NotFound";
import PaymentStatus from "./pages/payments";
import GoogleAdvertisements from "./pages/googleadvertisements";
import GoogleAdvertisementsATF from "./pages/googleadvertisementsatf";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Router>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/terms" component={Terms} exact />
        <Route path="/policy" component={Policy} exact />
        <Route path="/disclaimer" component={Disclaimer} exact />
        <Route path="/pricing" component={Pricing} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/coach" component={Coach} exact />
        <Route path="/faq" component={FAQ} exact />
        <Route path="/transformation" component={Transformation} exact />
        <Route path="/coaches" component={Coaches} exact />
        <Route path="/payment-status" component={PaymentStatusWrapper} exact />
        <Route path="/gettingstarted" component={GoogleAdvertisements} exact />
        <Route path="/new" component={GoogleAdvertisementsATF} exact />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
      <Footer />
      <WhatsAppButton />
    </Router>
  );
}

const PaymentStatusWrapper = (props) => {
  const {
    location: { state },
  } = props;
  if (!state) {
    return <Redirect to="/not-found" />;
  }

  const {
    success,
    amount,
    orderId,
    paymentId,
    paymentDateTime,
    description,
    reason,
  } = state;

  return (
    <PaymentStatus
      success={success}
      amount={amount}
      orderId={orderId}
      paymentId={paymentId}
      paymentDateTime={paymentDateTime}
      description={description}
      reason={reason}
    />
  );
};

export default App;
