import React from "react";
import { useHistory } from "react-router-dom";

const PaymentStatus = ({
  success,
  amount,
  orderId,
  paymentId,
  paymentDateTime,
  reason,
  description,
}) => {
  const history = useHistory();

  const navigateToHome = () => {
    history.push("/");
  };
  console.log(
    success,
    amount,
    orderId,
    paymentId,
    paymentDateTime,
    reason,
    description
  );

  // Initialize Google Analytics

  return (
    <div className="flex items-center justify-center m-3 bg-gray-100">
      <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <div className="flex justify-center mb-4">
          {success ? (
            <div className="bg-green-500 rounded-full p-2 shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-8 h-8 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          ) : (
            <div className="bg-red-500 rounded-full p-2 shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-8 h-8 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
        </div>
        {success ? (
          <>
            <h1 className="text-2xl font-bold mb-2">Payment Success!</h1>
            <p className="mb-6">Your payment has been successfully done.</p>
          </>
        ) : (
          <>
            <h1 className="text-2xl font-bold mb-2">Payment Failed!</h1>
            <p className="mb-6">
              Unfortunately, your payment was not successful.
            </p>
            {reason && <p className="text-red-500 mb-4">{description}</p>}
          </>
        )}

        <div className="bg-gray-700 p-4 rounded-lg mb-4">
          <h2 className="text-lg font-semibold mb-2">Total Payment</h2>
          <p className="text-2xl font-bold">{amount.toLocaleString()}</p>
        </div>

        <div className="text-left">
          <p className="mb-2 flex justify-between">
            <span className="font-semibold">Payment Date:</span>{" "}
            {paymentDateTime !== undefined && paymentDateTime}
          </p>
          <p className="mb-2 flex justify-between">
            <span className="font-semibold">Order ID:</span>{" "}
            {orderId !== undefined && orderId.replace("order_", "")}
          </p>
          <p className="mb-2 flex justify-between">
            <span className="font-semibold">Payment ID:</span>{" "}
            {paymentId !== undefined && paymentId.replace("pay_", "")}
          </p>
        </div>

        <button
          onClick={navigateToHome}
          className="mt-6 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Navigate to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentStatus;
