import React from "react";
import lifestyleImg from "../../images/lifestyle.png";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";
import { Button } from "../ButtonElements";
import { LiaGrinTongueWink } from "react-icons/lia";
import { GiFruitBowl } from "react-icons/gi";

function MealSection() {
  // Initialize Google Analytics

  return (
    <div
      style={{ gap: "30px" }}
      className="flex flex-col items-center justify-center h-full"
    >
      <div className="flex flex-col items-center justify-center">
        <GiFruitBowl style={{ fontSize: "40px", color: "rgb(28,174,110)" }} />
        <p className="text-white uppercase text-center font-bold">
          Losing Fat with home food
        </p>
        <p className="text-center">
          Lose fat effectively with delicious, nutritious home-cooked meals.
          Enjoy balanced, wholesome food that fuels your body while shedding fat
        </p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <LiaGrinTongueWink
          style={{ fontSize: "40px", color: "rgb(28,174,110)" }}
        />
        <p className="text-white uppercase text-center font-bold">
          Satisfy your taste buds, indulge in your preferences
        </p>
        <p className="text-center">
          Delight your senses with flavors that cater to your unique cravings.
          Treat yourself to the indulgence you deserve.
        </p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <img
          src={lifestyleImg}
          alt="lifestyle_img"
          style={{ height: "50px", color: "rgb(28,174,110)" }}
        />
        <p className="text-white uppercase text-center font-bold">
          Curating a lifestyle that's as unique as you are
        </p>
        <p className="text-center">
          Creating a lifestyle that reflects your individuality and personal
          style. Embrace the uniqueness that sets you apart with every choice
          you make.
        </p>
      </div>
    </div>
  );
}

function InfoSection(props) {
  return (
    <>
      <InfoContainer id={props.id} lightBg={props.lightBg}>
        <InfoWrapper isPlanSection={props.isPlanSection}>
          <InfoRow imgStart={props.imgStart}>
            <TextWrapper>
              <TopLine>{props.topLine}</TopLine>
              <Heading lightText={props.lightText}>{props.headline}</Heading>
              <Subtitle darkText={props.darkText}>
                {props.isMealSection ? <MealSection /> : props.description}
              </Subtitle>
              {props.buttonDisplay ? (
                <BtnWrap>
                  <Button
                    reactScroll={props.reactScroll}
                    to={props.buttonLink}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={props.primary ? 1 : 0}
                    dark={props.dark ? 1 : 0}
                    dark2={props.dark2 ? 1 : 0}
                  >
                    {props.buttonLabel}
                  </Button>
                </BtnWrap>
              ) : null}
            </TextWrapper>
            <ImgWrap>
              <Img src={props.img} alt={props.alt} />
            </ImgWrap>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
}

export default InfoSection;
