import React from "react";
import {
  GetServicesContainer,
  ServicesShadowCardWrapper,
  ServicesP,
  GetServicesCard,
  ServicesH1,
} from "../Services/ServicesElements";
import { MdReportProblem } from "react-icons/md";
import { RxLapTimer } from "react-icons/rx";

function StepByStepGuide() {
  return (
    <div style={{display: "flex",width: "100%"}}>
      <div style={{width: "100%",display:"flex",justifyContent: "center",flexDirection:"column",alignItems:"center",gap:"20px"}}>
        <GetServicesCard>
          <RxLapTimer style={{ width: "150px", height: "150px" }} />
          <br />
          <ServicesH1>Don’t have time?</ServicesH1>
          <p className="text-sm text-center overflow-hidden">
            No problem. AdaptFit’s plans are designed  <br /> to fit into even the
            busiest schedules,  <br /> with workouts starting at just  <br />15- 30 minutes.
          </p>
        </GetServicesCard>
        <GetServicesCard>
          <MdReportProblem style={{ width: "150px", height: "150px" }} />
          <ServicesH1>Worried about results?</ServicesH1>
          <p className="text-sm text-center overflow-hidden">
            We offer a 30-day money-back <br />  guarantee if you don’t see results, <br/>
            we’ll refund your money
          </p>
        </GetServicesCard>
      </div>
    </div>
  );
}

export default StepByStepGuide;
