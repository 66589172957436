import React, { useState, useEffect, useRef } from "react";
import {
  FaStar,
  FaGoogle,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import userImg from "../images/user.png";

const cardWidth = 300; // Fixed width for each card
const cardHeight = 280; // Fixed width for each card
const hidePortion = 1.6; // Percentage of the card to hide
const transitionDuration = 300; // Transition duration in milliseconds

const ReviewsCarousel = ({ reviews }) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const containerRef = useRef(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const reviewsData = reviews;

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentReviewIndex((prevIndex) =>
          prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
        );
        setTransitioning(false);
      }, transitionDuration);
    }, 5000);
    return () => clearInterval(interval);
  }, [reviewsData]);

  const handleNext = () => {
    setTransitioning(true);
    setTimeout(() => {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
      );
      setTransitioning(false);
    }, transitionDuration);
  };

  const handlePrev = () => {
    setTransitioning(true);
    setTimeout(() => {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === 0 ? reviewsData.length - 1 : prevIndex - 1
      );
      setTransitioning(false);
    }, transitionDuration);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      if (touchStartX - touchEndX > 50) {
        handleNext();
      } else if (touchEndX - touchStartX > 50) {
        handlePrev();
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };

  const activeIndex = currentReviewIndex;

  // Initialize Google Analytics

  return (
    <div
      style={{ height: `${cardHeight}px` }}
      className="flex justify-center items-center relative"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={containerRef}
    >
      <div
        style={{ height: `${cardHeight}px` }}
        className="relative h-72 w-full flex overflow-hidden whitespace-pre-line"
      >
        {reviewsData.map((review, index) => {
          const isActive = index === activeIndex;
          const distanceFromActive = Math.abs(index - activeIndex);
          const isAdjacent = distanceFromActive === 1;
          const translation = (index - activeIndex) * cardWidth * hidePortion;
          let opacity;
          if (isActive) {
            opacity = 1;
          } else if (isAdjacent) {
            opacity = 0.2;
          } else {
            opacity = 0;
          }
          return (
            <a
              href={review.ratingLink}
              key={review.id}
              className={`absolute transition-opacity ${
                transitioning ? "transition-transform" : ""
              } ${
                transitioning ? "" : isActive || isAdjacent ? "" : "opacity-50"
              }`}
              style={{
                left: "50%",
                transform: `translateX(calc(-50% + ${translation}px))`,
                width: `${cardWidth}px`,
                zIndex: isActive ? 1 : 0,
                transition: `opacity ${transitionDuration}ms ease-in-out, transform ${transitionDuration}ms ease-in-out`,
                opacity,
                height: cardHeight,
              }}
            >
              <div
                className={`bg-black ${
                  isActive || isAdjacent ? "" : "bg-opacity-25"
                } text-white p-4 rounded-lg`}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="flex items-center justify-center mb-2">
                  <div
                    style={{
                      borderRadius: "50%",
                      border: "4px solid rgb(28,174,110)",
                    }}
                    className="w-20 h-20 flex items-center justify-center m-2"
                  >
                    <img
                      style={{
                        borderRadius: "50%",
                      }}
                      src={review.photo}
                      alt={review.username}
                      onError={userImg}
                      className="w-16 h-16 rounded-3xl"
                    />
                  </div>
                </div>
                <p className="text-center text-xl font-semibold">
                  {review.username}
                </p>
                <div className="flex items-center justify-center mb-2">
                  <FaGoogle className="text-green-500 mr-1" />
                  <span className="text-green-500">
                    {review.rating.toFixed(1)}
                  </span>
                </div>
                <div className="flex justify-center items-center mb-2">
                  {[...Array(5)].map((_, i) => (
                    <FaStar
                      style={{ color: "#FBBF24" }}
                      key={i}
                      className="text-yellow-500"
                    />
                  ))}
                </div>
                <p
                  style={{
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    marginBottom: "3px",
                  }}
                  className="flex justify-center items-center text-sm text-center truncate-lines-3"
                >
                  {review.review}
                </p>
              </div>
            </a>
          );
        })}
        <div
          id="reviews-left"
          className="absolute top-1/2 -translate-y-1/2 cursor-pointer text-white text-3xl md-p-5"
          style={{
            color: "black",
            left: "0px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          onClick={handlePrev}
        >
          <FaChevronLeft />
        </div>
        <div
          id="reviews-right"
          className="absolute top-1/2 -translate-y-1/2 cursor-pointer text-white text-3xl"
          style={{
            color: "black",
            right: "0px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          onClick={handleNext}
        >
          <FaChevronRight />
        </div>
      </div>
    </div>
  );
};

export default ReviewsCarousel;
