/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import TransformationsCarousel from "../components/GoogleAds/TransformationsCarousel";
import ChooseAdaptFit from "../components/GoogleAds/ChooseAdaptFit";
import StepByStepGuide from "../components/GoogleAds/StepByStepGuide";
import Contact from "./contact";
import { motion } from "framer-motion";
import Coaches from "./coaches";
import { useInView } from "react-intersection-observer";
import MoneyBackImg from "../images/money_back.PNG";
import ImgCustomizeHealth from "../images/CustomHealth.JPG";
import GETFAQ from "../pages/getfaq";
import { FaRocket } from "react-icons/fa";

function GoogleAdvertisementsATF() {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const targetRef = useRef(null);
  const TopStartupTransformation = () => {
    return (
      <>
        <div ref={targetRef} className="bg-black text-white text-center p-2">
          <h1 className="text-4xl text-white font-medium sm:text-3xl">
            <p className="flex items-center justify-center text-white font-bold py-2 px-4 gap-4">
              <FaRocket />
              Start Your Transformation Today
            </p>
          </h1>
          <Contact isFromGoogleAdd={true} />
          <button
            onClick={() => RedirectToPricing()}
            className="bg-green-500 hover:bg-green-700 text-text font-bold py-2 px-4 rounded-full"
          >
            <p className="flex justify-center text-center text-2xl text-black">
              <MdRemoveRedEye size={35} /> VIEW PLANS
            </p>
          </button>
        </div>
      </>
    );
  };

  const StartupTransformation = () => {
    return (
      <>
        <div ref={targetRef} className="bg-black text-white text-center p-2">
          <h1 className="text-4xl text-white font-medium sm:text-3xl">
            <p className="flex items-center justify-center text-white font-bold py-2 px-4 gap-4">
              <FaRocket />
              Start Your Transformation Today
            </p>
          </h1>
          <h1 className="text-xl text-white font-medium sm:text-3xl">
            Schedule Your Free Consultation Today
          </h1>
          <Contact isFromGoogleAdd={true} isFrom2GoogleAdd={true} />
          <div className="mt-12 flex justify-center">
            <br />
            <img
              src={MoneyBackImg}
              alt="Money Back Guarantee"
              className="h-40 w-50"
            ></img>
          </div>
          <h3 className="mt-5 text-2xl font-bold">
            30-Day Money-Back Guarantee
          </h3>
          <p className="mt-4">
            At AdaptFit, we are committed to helping you achieve your health and
            fitness goals.
          </p>
          <p>
            We are confident in the effectiveness of our personalized fitness
            and nutrition plans, but if you are not satisfied with your progress
            within the first 30 days, we offer a full money-back guarantee. No
            questions asked.
          </p>
          <button
            onClick={() => RedirectToPricing()}
            className="mt-10 bg-green-500 hover:bg-green-700 text-text font-bold py-2 px-4 rounded-full"
          >
            <p className="flex justify-center text-center text-2xl text-black">
              <MdRemoveRedEye size={35} /> VIEW PLANS
            </p>
          </button>
        </div>
      </>
    );
  };

  const RedirectToPricing = () => {
    window.location.href = "/pricing";
  };

  const ScrollToDiv = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const CustomizeHealth = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${ImgCustomizeHealth})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
        }}
        className="flex justify-center items-center bg-black text-white px-4 h-96 md:h-400"
      >
        <div className="flex w-1/2 md:w-full flex-col justify-center items-center space-y-6 h-64 md:h-96 px-2">
          <motion.h1
            className="text-5xl md:text-4xl font-bold bg-gradient-to-r from-green-300 to-green-500 inline-block text-transparent bg-clip-text"
            initial={{ opacity: 0, y: -50 }}
            animate={inView1 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            <span className="bg-gradient-to-r from-gray-300 to-white inline-block text-transparent bg-clip-text">
              Lose Weight with Custom Fitness Plans <br /> Designed for Busy
              Professionals
            </span>
          </motion.h1>
          <motion.p
            className="text-white text-base"
            initial={{ opacity: 0, y: 50 }}
            animate={inView1 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.2 }}
            ref={ref1}
          >
            <div className="flex items-center justify-center">
              <p className="text-xl md:text-lg">
                Achieve your fitness goals in just 30 minutes a day with
                personalized workouts and nutrition plans.
              </p>
            </div>
          </motion.p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>{CustomizeHealth()}</div>
      <div>{TopStartupTransformation()}</div>
      <TransformationsCarousel />
      <div className="mt-10" />
      <ChooseAdaptFit />
      <div className="mt-10" />
      <StepByStepGuide />
      <Coaches isFromGoogleAdd={true} />
      <GETFAQ />
      <div>{StartupTransformation()}</div>
    </>
  );
}

export default GoogleAdvertisementsATF;
