import React from "react";
import Screenshot1 from "../images/3.png";
import Screenshot2 from "../images/4.png";
import Screenshot3 from "../images/1.png";
import Screenshot4 from "../images/2.png";

const MobileApp = () => {
  // Initialize Google Analytics

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 py-6">
      <div className="w-full flex flex-col gap-6 bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-3xl font-bold mb-6 text-center">
          Mobile Application
        </h2>
        <div className="flex flex-wrap justify-center items-center gap-4 mb-4">
          <div className="flex justify-center items-center">
            <div className="w-64 h-96 bg-gray-200 rounded-lg overflow-hidden shadow-lg">
              <img
                src={Screenshot1}
                alt="Screenshot 1"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="w-64 h-96 bg-gray-200 rounded-lg overflow-hidden shadow-lg">
              <img
                src={Screenshot2}
                alt="Screenshot 2"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="w-64 h-96 bg-gray-200 rounded-lg overflow-hidden shadow-lg">
              <img
                src={Screenshot3}
                alt="Screenshot 3"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="w-64 h-96 bg-gray-200 rounded-lg overflow-hidden shadow-lg">
              <img
                src={Screenshot4}
                alt="Screenshot 4"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center gap-4 flex-wrap">
          <a
            href="https://play.google.com/store/apps/details?id=com.clientapp.adaptfit&hl=en_IN&pli=1"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Play Store"
              className="w-32 h-10"
            />
          </a>
          <a
            href="https://apps.apple.com/az/app/adaptfit/id6473173768"
            className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/5d/Available_on_the_App_Store_%28black%29.png"
              alt="App Store"
              className="w-32 h-10"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default MobileApp;
