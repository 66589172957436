import React, { useEffect } from "react";
import TransformationCard from "../components/TransformationCard";
import {
  Transformation_1,
  Transformation_10,
  Transformation_11,
  Transformation_12,
  Transformation_13,
  Transformation_14,
  Transformation_15,
  Transformation_16,
  Transformation_17,
  Transformation_18,
  Transformation_2,
  Transformation_3,
  Transformation_4,
  Transformation_5,
  Transformation_6,
  Transformation_7,
  Transformation_8,
  Transformation_9,
} from "../images/transformations";

const Transformation = () => {
  const transformations = [
    {
      id: 1,
      quote:
        "This journey has transformed me from within, instilling mental strength, unwavering self-belief, and an unyielding spirit.I'm incredibly grateful to my Coach, for his unwavering support and expertise in guiding me on this transformative journey.He has been really supportive and understanding throughout. The kind of motivation and push he has given me didn't even feel like work and we came so far so easily. he could not wait for completion of the week for a follow up, sometimes we connected even thrice a week. He has been easily accessible and kept checking on me whenever I was slipping off track.To be honest, I have found a great friend in him and looking forward to achieve greater levels of fitness.Thank you coach for handholding me and achieving my goals",
      src: Transformation_1,
      name: "Harsh Bhatnagar",
      startingWeight: "85 Kg",
      currentWeight: "72 Kg",
      timeTaken: "20 weeks",
    },
    {
      id: 2,
      quote:
        "I Struggled with being overweight and tried various diets like GM diet, low carb diet, naturopathy, fasting etc, lost some weight but regained after sometime. Then I thought I should do something seriously and enrolled. He guided me with a quantified diet chart and exercise routine. He never missed his weekly calls to ask for my updates and explained workouts. He was always available for guidance Never missed my 10000 steps and exercise routine except for medical reasons When I started year back l never believed that I will loose this much weight that too with good calorie intake.His guidance helped me become a better version of myself physically and mentally. My confidence has definitely increased.Forever Grateful to coach for his support in transforming my life.",
      src: Transformation_2,
      name: "Janet Irani",
      startingWeight: "94 kg",
      currentWeight: "58 Kg",
      timeTaken: "40 weeks",
    },
    {
      id: 3,
      quote:
        "This is a gratitude post for my mentor.It's been a life changing experience. He was there to guide me and motivate me when I was struck somewhere. He has been very supportive and always been in touch since the day I have enrolled him. Also he has customized my diet chart after discussing with me.Thanks you so much once again...",
      src: Transformation_3,
      name: "Toshit Tarun",
      startingWeight: "73 Kg",
      currentWeight: "61 Kg",
      timeTaken: "12 weeks",
    },
    {
      id: 4,
      quote:
        "It was not a easy journey specially for a foodie like me but now my lifestyle is completely changed.'It's not a race, It's a journey' Never thought that I would be 11Kgs lighter.I really want to thank my mentor. He is very professional as well as very caring and cooperative. He taught me how to change life into a healthy lifestyle. As I have started my journey I was not sure that i can do something but he helped me out to stay focus and motivated by consistent follow ups.He guided me with the workouts even though i never stepped into gym before, that helped me a lot to lose fat and inches in a healthy and informed way.My husband was initially skeptical regarding me joining this but once he saw the visible changes and the professionalism, he asked me to enroll for more 3 months after my first package expired. He never saw me this motivated earlier, all thanks to my mentor.",
      src: Transformation_4,
      name: "Vinita Gupta",
      startingWeight: "86 Kg",
      currentWeight: "76 Kg",
      timeTaken: "4 months",
    },
    {
      id: 5,
      quote:
        "Finally!! it happened...My weight was 77.89Kg and now it's 62kg (15kg lost forever and more on) I started my journey in the month of March and the end of 12 weeks I am amazed by the result. My body has changed to be better and in a proper shape, from XXL size it came up to M size.Here I learnt about quantified nutrition and how to adjust training according to my schedule.... I won't lie, it's hard, it pushes you, but nothing worth having comes easy.Thank you so much for been such a wonderful coach.",
      src: Transformation_5,
      name: "Rashmi",
      startingWeight: "77.89 Kg",
      currentWeight: "62 Kg",
      timeTaken: "12 weeks",
    },
    {
      id: 6,
      quote:
        "I can't believe the changes that happened after I joined Adaptfit. I didn't have to give up on my favorite food, yet I lost weight in a healthy manner under proper guidance of my coach who helped me and encouraged me even when I was lacking on the exercise front. it has been a transformative experience. His personalized approach to fitness and nutrition helped me break through plateaus and achieve my goals. He was always available to answer my queries and his support and positive attitude kept me motivated. Its my halfway transformation but wanted to express my gratitude Towards him. I did all this only with home workout quantified diet. And 12 k steps everyday. He gave me workout which can be easily done at home. Thanks to his guidance i have seen good improvement in my energy level, strength and overall well-being. I highly recommend him to anyone looking to make the changes in their health and lifestyle",
      src: Transformation_6,
      name: "Sant Kumar",
    },
    {
      id: 7,
      quote:
        "The most important lesson I've learned is the value of unwavering consistency and trusting a knowledgeable Coach. I've realized that honesty is crucial - even if I slip up on my diet, I inform him.Although I'm currently in the midst of my transformation, I already feel healthier with significantly improved stamina. I've resumed playing badminton and cricket regularly. Even during times when I'm not strictly on a diet, I pay closer attention to the amount of junk food I consume. Overall, I've cultivated the invaluable habit of eating healthily.",
      src: Transformation_7,
      name: "Ritesh",
    },
    {
      id: 8,
      quote:
        "Thanks to Coach for consistent guidance , I've managed to overcome numerous hurdles and make steady progress on my fitness journey. With his support, l've not only lost fat but also gained valuable insights into building muscle and grasped the significance of maintaining a calorie deficit. His expertise has been instrumental in helping me understand the importance of nutrition and its role in achieving my fitness goals.",
      src: Transformation_8,
      name: "Dhruv Javeri",
    },
    {
      id: 9,
      quote:
        "I used to be a very active child in my school and college time and represented my school and college in various state-level sports.As I started my professional life, things started to change. Being a big-time foodie, l started consuming a lot of Junk food and I was diagnosed with Hypothyroidism. I still represented my company in various corporate basketball tournaments but my performance was never up to the mark as I started gaining weight. Due to this excessive weight, One day while playing I tore my ACL and had to undergo an ACL reconstruction surgery, with this I had to leave basketball and I thought I will never be able to play again.l was weighing 82kg and that was when | realized I had to do something, my sister-in-law suggested  I enrolled with Coach. He was patient with me and provided me with all the help I could ask for. He was there for me whenever I needed him and I could eat whatever I liked and still reduce weight What else could I have asked for). I started gaining strength and losing weight at the same time and I again started playing basketball. I just want to thank him as he helped me to fulfill my dream of being able to play basketball again. I have a dream to be able to participate in power lifting.",
      src: Transformation_9,
      name: "Sushree Srabani",
      startingWeight: "82 Kg",
      currentWeight: "63 Kg",
      timeTaken: "1 year",
    },
    {
      id: 10,
      quote:
        "Started my Fitness journey 6 months back . I am grateful to my coach for the constant support and the encouragement through out my weight loss journey.He is a phenomenal coach and very friendly, his expertise, patience and motivational words made all the difference in my transformation. With his day to day guidance I lost 13kgs and a lot of inches, my health got improved and I become more energetic, active and confident. I highly recommend him.",
      src: Transformation_10,
      name: "Devansh Vithlani",
      startingWeight: "87 Kg",
      currentWeight: "74 Kg",
      timeTaken: "6 months",
    },
    {
      id: 11,
      quote:
        "Prior experience: Very minimal. Wasted a lot of gym memberships without making any use of it. Size down:Top: From hardly fitting in XXL to M Bottom: 38 to 32 I was a fat guy more than 75% of my life.Just out of college, I was 72kgs. I met with an accident and I was bed ridden for almost 2 months. Over the period of 6 months that followed, I gained 20kgs because of a lot of eating outside, junk food, irregular sleep cycles, stress eating. After all this was able to lose 26 kgs. All thanks to my coach🙌🏼",
      src: Transformation_11,
      name: "Aditya",
      startingWeight: "106 Kg",
      currentWeight: "83 Kg",
      timeTaken: "12 weeks",
    },
    {
      id: 12,
      quote:
        "I was never so thin girl since my childhood but always was wanted to look fit....then my friend harsha kunal kothari(thanq... finally tune piche pad kr krwa hi diya) introduced me but it took me 1 years to start my journey... one sunday i finally decided to enroll myself under a mentor... i didnt talked to him before bt by god grace i got to most wonderful mentor. he is  gem of person... he alwaz listened very patiently n answered to all my questions... i think i irritated him d most with all d silly questions... I'll alwaz owe u... i didnt knew i can get fit before enrolling under him n dat too with lots of food...he also taught about calories macros n now i can make my diet plan as per my requirement... n thanq sir for those diet break.. they were lyk blessings for me A big thank u to my husband dilip sharma... he was alwaz a support pillar for me before all this i was like palta toh hona h bt don't know how???",
      src: Transformation_12,
      name: "Sneha Sharma",
      startingWeight: "83.4 Kg",
      currentWeight: "67 Kg",
    },
    {
      id: 13,
      quote:
        "Hey, I have been an obese kid since my childhood Have tried a lot of diets, different forms of workouts be it dance, sports anything I always attained temporary results, but then I heard about coach from a friend of mine. He had lost a lot of weight. Then on my 24th bday when I almost touched 100kgs, I decided to take a leap of faith and enrolled under Coach. He has been so patient and motivating. My health conditions like pcod, migraine, Sinus have all improved considerably I have enrolled 4 times under him and the results have just gotten better Before my 25th bday I had lost more than 25kgs. Sir is a true inspiration, he always calls to clear doubts in a couple of minutes. He has solutions to almost all health issues and he is always available like 24/7 (literally) Thank you so much for making this happen, I do have a long way to go but it's always an achievement to meet old friend and colleagues who don't recognise me after the transformation",
      src: Transformation_13,
      name: "Chandni",
      startingWeight: "95 Kg",
      currentWeight: "68 Kg",
      timeTaken: "7 months",
    },
    {
      id: 14,
      quote:
        "I was always into trying to reduce weight and getting leaner. I was not following a proper plan but getting myself into unhealthy way of losing weight like starving and not eating enough calories. And, somehow always end up getting disappointed because without any plan you can't achieve anything.So one of my friend recommended and that’s how I started my Fitness journey and it has been 3 months but can't believe my own transformation. He is such a knowledgeable coach and making me understand how the body system works.I can see a better version of myself now, I have learnt to be consistent, motivated, determined, disciplined and full of energy, still a long way to go.I am really thankful to my Coach for this wonderful and amazing Change",
      src: Transformation_14,
      name: "Deepak Gupta",
    },
    {
      id: 15,
      quote:
        "Before : High Blood pressure and sugar levels, was on medication After transformation: Blood sugar and Blood pressure is normal with no medication I started my Fitness journey 5 months back.Prior to that I had been trying several ways to reduce weight and fat, however not yielding results. Now I understand why.Right guidance by an expert, constant motivation and consistent follow up's is the key to convert the efforts into results and I could finally do that, thanks to my coach. He has profound knowledge in nutrition, diet and exercise. He explains why to do or eat something and why not to. Its easier to adopt or change when you know the impact. With help of customized plans and weekly discussions I could start seeing the change which fueled my journey further. The best part is that unlike any other forums, I was not asked to complete drop numerous things and only be on selective diet, which is often the first pushback to anyone starting the journey. He created a balanced diet chart and making small changes as per the progress. Now Fitness is not something which I need to do, instead is something which I want to do.",
      src: Transformation_15,
      name: "Mahendra kumar",
      startingWeight: "97 Kg",
      currentWeight: "84 Kg",
      timeTaken: "5 months",
    },
    {
      id: 16,
      quote:
        "Deciding to start this journey was the best decision I made.From sleeping at 4 am to waking up at 4 am, I learned that breaking out of old habits and trying new things wasn't as hard as I thought.I initially started my experiments with different workout formats and diets which didn't work much. After getting a coach to design my food intake and training  helped me establish a routine. many of my perspectives only cleared up when I saw real results.Once I signed up for transformation, I focused on balanced meals, regular workout sessions and trying methods to stay calm and relaxed.Thank you so much Coach for being there and your timely response to my questions are much appreciated.",
      src: Transformation_16,
      name: "Raka",
    },
    {
      id: 17,
      quote:
        "I never posted anything because I have been a big introvert and probably will crawl right back in my shell after posting this. It took me a lot of courage to post here today. I started my journey in July 2020 when I got a wake up call with my weight reaching 80 kg and me feeling breathless after even a little activity. These past 6 months have not been exactly a cakewalk but now I am in a much better place physical.These are the few things I have able to achieve along the process: 1.From not being able to walk 5k steps to finishing 15k easily. 2.From pausing to take a breath every few minutes in a 10 min HIIT workout to finishing 3 HIIT workouts in an hour. 1.From sitting and watching my kids play in the park to playing and running around with them.2.From not even pausing to look myself in the mirror to asking my husband to take my pics almost everyday. 3.Weight- 80 to 63.3 kg Body fat %- 43.5 to 31 I was not always super motivated to workout and had a lot of cravings for all the yummy food but have realized that being consistent and disciplined will make us",
      src: Transformation_17,
      name: "Rasika vaddy",
      startingWeight: "80 Kg",
      currentWeight: "63.3 Kg",
    },
    {
      id: 18,
      quote:
        "I have been able to lose a significant amount of weight, gain confidence, and finally feel comfortable in my own skin. I have lost weight but I have also gained strength which has greatly improved the way I see my own body. Thank you Coach for your dedication and guidance in helping me achieve my fitness goals. Your knowledge and expertise have been invaluable to me in this journey, and I appreciate your willingness to tailor workouts to meet my individual needs and abilities.",
      src: Transformation_18,
      name: "Yogesh Mange",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Initialize Google Analytics

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="max-w-7xl flex justify-center flex-col items-center mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl md:text-3xl text-green-500 uppercase font-bold text-center mb-12">
          Transformations
        </h1>
        <div className="flex flex-wrap gap-4 justify-center items-center">
          {transformations.map((transformation, index) => (
            <TransformationCard
              key={index}
              name={transformation.name}
              quote={transformation.quote}
              src={transformation.src}
              startingWeight={transformation.startingWeight}
              currentWeight={transformation.currentWeight}
              timeTaken={transformation.timeTaken}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transformation;
