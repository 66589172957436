import React, { useRef, useState } from 'react';
import Logo from '../images/iconLogo.png';
import { FaCreditCard } from 'react-icons/fa';
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import emailjs from "@emailjs/browser";
import Loader from './Loader';
import { useHistory } from 'react-router-dom';


const ModalForm = ({ isOpen, onClose,amt }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    coach: '',
    phoneNumber: ''
  });
  const form = useRef();
  const history = useHistory()
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadRazorPay = async(amount) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/order`,{
      method: "POST",
      body: JSON.stringify({
        amount: amount * 100,
        currency: "INR",
        receipt: "qwsaq1"
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });

    const order = await response.json();
    console.log(order);

    var options = {
      "key": process.env.REACT_APP_RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
      "amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Adaptfit", //your business name
      "description": "Dive in this fitness pool",
      "image": "https://lh3.googleusercontent.com/p/AF1QipPqlGZA6QYq0ZNfeh6P3v6o-ztXOBTpXIRJpp2c=s1360-w1360-h1020",
      "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response){
          const body = {...response};
          const validateRes = await fetch(`${process.env.REACT_APP_BACKEND_URL}/order/validate`,{
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json"
            }
          });
          const jsonRes = await validateRes.json();
          history.push("/payment-status",
            {success: jsonRes.success, amount, orderId: jsonRes.orderId, paymentId: jsonRes.paymentId,paymentDateTime: jsonRes.paymentDateTime }
          )
      },
      "prefill": {
          "name": formData.name,
          "email": formData.email,
          "contact": formData.phoneNumber
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#1cae6e"
      }
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.on('payment.failed', function (response){
    history.push("/payment-status",
      {success: false, amount, reason: response.error.reason, description: response.error.description,orderId: response.error.metadata.order_id, paymentId: response.error.metadata.payment_id,paymentDateTime: new Date().toLocaleDateString("en-GB"),source : response.error.source}
    )
  });
  rzp1.open();
  setIsLoading(false);
  setFormData({
    name: '',
    email: '',
    coach: '',
    phoneNumber: ''
  });
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formErrors = {};
    if (!isValidPhoneNumber(formData.phoneNumber)) {
      formErrors.phoneNumber = "Please enter a valid phone number.";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    loadRazorPay(amt);

    emailjs
    .sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_ENROLL_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    )
    .then(
      (result) => {
        onClose();
      },
      (error) => {
        console.log(error.text);
        setIsLoading(false);
        onClose();
      }
    );
  };

  const handlePhoneNuChange = (phoneNumber) => {
    if (phoneNumber !== undefined) {
      setFormData({
        ...formData,
        "phoneNumber": phoneNumber
      });
      setIsValid(isValidPhoneNumber(phoneNumber));
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: isValidPhoneNumber(phoneNumber)
          ? ""
          : "Invalid phone number",
      }));
    }
  };

  return (
    <div className="flex items-center justify-center bg-gray-900">
      {isOpen && (
        <>
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md">
          {isLoading ? <Loader /> : null}
            <button
              onClick={onClose}
              className="absolute top-2 right-2 text-gray-400 hover:text-white transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-semibold text-white flex flex-col justify-center items-center mb-10">
              <img src={Logo} alt='logo' className='h-10 w-10'/>
            </h2>
            <form ref={form} onSubmit={handleSubmit}>
              <div className="mb-5">
                <label className="block text-sm font-medium text-gray-400 mb-2" htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full text-xl px-2 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-white"
                  placeholder="Your full name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-5">
                <label className="block text-sm font-medium text-gray-400 mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-2 py-2 text-xl bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-white"
                  placeholder="Email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-5">
                <label className="block text-sm font-medium text-gray-400 mb-2" htmlFor="coach">Coach</label>
                <select
                  id="coach"
                  name="coach"
                  className="w-full px-2 text-xl py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-white"
                  value={formData.coach}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select a coach</option>
                  <option value="Dushyant Shahane">Dushyant Shahane</option>
                  <option value="Rohit Shahane">Rohit Shahane</option>
                </select>
              </div>
              <div className="mb-5">
                <label className="block text-sm font-medium text-gray-400 mb-2" htmlFor="phoneNumber">Phone Number</label>
                <PhoneInput
                  defaultCountry="IN"
                  name="phoneNumber"
                  className={`w-full px-2 text-xl py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-white ${isValid} ? "border-gray-200" : "border-red-500"`}
                  placeholder="Phone number"
                  value={formData.phoneNumber}
                  onChange={handlePhoneNuChange}
                  required
                />
                {!isValid && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.phoneNumber}
                  </p>
                )}
                {errors.message && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.message}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="w-full text-xl bg-green-500 text-white py-2 rounded-md hover:bg-green-600 transition duration-300 flex justify-center items-center gap-5"
              >
                <FaCreditCard />
                Proceed to Checkout
              </button>
            </form>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default ModalForm;
