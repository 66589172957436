import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import RatingsReviewsCarousel from "../components/ReviewsCarousel";
import { MdStar } from "react-icons/md";
import { getCoachData } from "../utils/getCoachData";

const Coach = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const coachParam = queryParams.get("coach");

  const coach = getCoachData(coachParam);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Initialize Google Analytics

  return (
    <>
      <div className="h-auto w-screen overflow-auto">
        <div className="flex relative flex-col items-center justify-center">
          <div
            style={{
              backgroundImage: `url(${coach.coverImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="h-96 sm:h-80 w-full"
          ></div>
          <div
            style={{
              borderTopLeftRadius: "32px",
              borderTopRightRadius: "32px",
            }}
            className="flex absolute h-20 bg-white w-full top-80 sm:top-60"
          >
            <div
              style={{
                backgroundImage: `url(${coach.profileImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className="h-40 w-40 absolute ml-4 transform -translate-y-1/2 rounded-lg sm:h-20 sm:w-20"
            ></div>
            <p
              style={{ width: `${coach.id === 2 ? "220px" : "auto"}` }}
              className="text-black text-2xl uppercase pt-2 font-bold transform translate-x-52 sm:translate-x-1/2 sm:text-xl"
            >
              {coach.name}
            </p>
          </div>
          <div className="z-10 mt-24 sm:m-0">
            <a
              href="https://g.co/kgs/K6GYwe2"
              className="flex justify-center items-center font-extrabold"
            >
              <h1 className="bg-black text-white px-6 py-2 rounded-xl uppercase text-2xl md:text-xl text-center flex items-center gap-2">
                <MdStar style={{ display: "inline" }} />
                Ratings & Reviews
              </h1>
            </a>
            <div style={{ height: "300px" }} className="m-2">
              <RatingsReviewsCarousel reviews={coach.reviews} />
            </div>
            <h1 className="text-center text-4xl md:text-2xl uppercase font-extrabold text-green-500">
              About
            </h1>
            <div className="flex items-center justify-center m-5 p-5 shadow-lg rounded-md">
              <p className="text-center font-medium text-gray text-lg">
                {coach.about}
              </p>
            </div>
            <h1 className="text-center text-4xl md:text-2xl uppercase font-extrabold text-green-500">
              Certifications
            </h1>
            <div className="flex items-center justify-center w-full mt-10 gap-8 flex-wrap">
              {coach.certifications.map((cert, index) => (
                <a
                  key={index}
                  href={cert.href}
                  style={{
                    backgroundImage: "url('/certificate.webp')",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="shadow-xl rounded-lg h-48 w-56 bg-gray-50 relative md:h-28 md:w-40"
                >
                  <span className="absolute text-md font-bold top-3 left-7">
                    {cert.title}
                  </span>
                  <small
                    style={{ fontSize: "8px" }}
                    className="absolute text-gray-500 top-9 left-7"
                  >
                    {cert.subtitle}
                  </small>
                </a>
              ))}
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Coach;
