import React from "react";

import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";

export const HeroContainer = ({ children }) => (
  <div className="flex justify-center bg-black items-center px-4 h-800 relative">
    {children}
  </div>
);

export const HeroBg = ({ children }) => (
  <div className="absolute inset-0 w-full h-full overflow-hidden flex items-center justify-center">
    {children}
  </div>
);

export const VideoBg = (props) => (
  <video
    {...props}
    id="bg-video"
    className="w-full h-full object-cover bg-gray-900"
    preload="auto"
  />
);

export const HeroContent = ({ children }) => (
  <div className="z-3 max-w-screen-xl absolute py-2 px-6 flex flex-col items-center">
    {children}
  </div>
);

export const HeroH1 = ({ isVisible, children }) => (
  <h1
    style={{
      transition: "opacity",
      transitionDuration: "20s",
      transitionBehavior: "ease-in-out",
    }}
    className={` text-white ${
      isVisible ? "opacity-100" : "opacity-0"
    } text-center font-bold text-5xl md:text-4xl sm:text-3xl`}
  >
    {children}
  </h1>
);

export const HeroP = ({ isVisible, children }) => (
  <p
    style={{
      transition: "opacity",
      transitionDuration: "20s",
      transitionBehavior: "ease-in-out",
    }}
    className={`text-white ${
      isVisible ? "opacity-100" : "opacity-0"
    } mt-6  text-2xl sm:text-xl text-center max-w-screen-sm`}
  >
    {children}
  </p>
);

export const HeroBtnWrapper = ({ isVisible, children }) => (
  <div
    style={{
      transition: "opacity",
      transitionDuration: "20s",
      transitionBehavior: "ease-in-out",
    }}
    className={`${isVisible ? "opacity-100" : "opacity-0"}
  mt-8 flex flex-col items-center`}
  >
    {children}
  </div>
);

export const ArrowForward = () => <MdArrowForward className="ml-2 text-xl" />;

export const ArrowRight = () => (
  <MdKeyboardArrowRight className="ml-2 text-xl" />
);
