import { reviews } from "../components/reviewsData";
import { reviewsRohit } from '../components/reviewsDataRohit'

export const coachData = {
  rohit: {
    id: 2,
    name: "Rohit Shahane",
    profileImage: "/rohit_profile.jpg",
    coverImage: "/rohit.png",
    about: `I'm Rohit, a Certified Personal Trainer and Nutrition Expert with certifications from the Institute of Fitness and Nutrition Science (INFS) and K11. With a focus on fat loss, muscle gain, general well-being, and complete transformations, I bring over 5 years of experience in the fitness industry. My journey includes working with prominent platforms like FITTR online coaching, Fitness Mantra, and Talwalkar’s Gyms, where I've successfully coached a diverse range of clients worldwide. I believe in tailoring customized fitness and nutrition plans that are not only effective but also sustainable and enjoyable for my clients. My approach involves educating individuals about fitness and nutrition, empowering them with the knowledge for long-term health and fitness goals. Whether you're looking to shed pounds, build muscle, or improve your overall well-being, I'm dedicated to minimizing discomfort during your transformation journey and helping you achieve your desired fitness level.`,
    certifications: [
      { href: "/INFS.pdf", title: "INFS", subtitle: "Nutrition & Fitness" },
      {
        href: "/CNFC.pdf",
        title: "CNFC",
        subtitle: "Certified Nutrition & Fitness Coach",
      },
      {
        href: "/Exercise.pdf",
        title: "ESS",
        subtitle: "Exercise science specialist",
      },
    ],
    reviews: reviewsRohit
  },
  dushyant: {
    id: 1,
    name: "Dushyant Shahane",
    profileImage: "/dp.jpg",
    coverImage: "/cover_bg.jpg",
    about: `I am here to guide you on this incredible journey. We'll start by listening to your aspirations, understanding your unique needs, and crafting a personalized plan that fits your lifestyle. Together, we'll conquer obstacles, break through plateaus, and celebrate your successes. I will be your educator, and your unwavering support system every step of the way. With my expertise and your dedication, there's nothing standing between you and the healthier, stronger, and happier you. Let's embark on this empowering adventure together. Contact me today, and let's get started!`,
    certifications: [
      {
        href: "/CSCS.jpeg",
        title: "CSCS",
        subtitle: "Strength & Conditioning",
      },
      {
        href: "/NFC.pdf",
        title: "NFC",
        subtitle: "Nutrition Fitness Consultant",
      },
      {
        href: "/SFN.png",
        title: "SFN",
        subtitle: "Specialist in Fitness Nutrition",
      },
      {
        href: "/ESS.pdf",
        title: "ESS",
        subtitle: "Exercise science specialist",
      },
    ],
    reviews: reviews
  },
};
