import Transformation_1 from "./transformation_1.JPEG";
import Transformation_2 from "./transformation_2.JPEG";
import Transformation_3 from "./transformation_3.JPEG";
import Transformation_4 from "./transformation_4.JPEG";
import Transformation_5 from "./transformation_5.JPEG";
import Transformation_6 from "./transformation_6.jpg";
import Transformation_7 from "./transformation_7.JPEG";
import Transformation_8 from "./transformation_8.JPEG";
import Transformation_9 from "./transformation_9.JPEG";
import Transformation_10 from "./transformation_10.jpg";
import Transformation_11 from "./transformation_11.JPEG";
import Transformation_12 from "./transformation_12.JPEG";
import Transformation_13 from "./transformation_13.JPEG";
import Transformation_14 from "./transformation_14.JPEG";
import Transformation_15 from "./transformation_15.JPEG";
import Transformation_16 from "./transformation_16.JPEG";
import Transformation_17 from "./transformation_17.JPEG";
import Transformation_18 from "./transformation_18.jpg";
import Transformation_19 from "./transformation_19.JPEG";
import Transformation_20 from "./transformation_20.JPEG";
import Transformation_21 from "./transformation_21.JPEG";

export {
  Transformation_1,
  Transformation_2,
  Transformation_3,
  Transformation_4,
  Transformation_5,
  Transformation_6,
  Transformation_7,
  Transformation_8,
  Transformation_9,
  Transformation_10,
  Transformation_11,
  Transformation_12,
  Transformation_13,
  Transformation_14,
  Transformation_15,
  Transformation_16,
  Transformation_17,
  Transformation_18,
  Transformation_19,
  Transformation_20,
  Transformation_21,
};

const gettingStartedImages = [
  {
    src: Transformation_19,
    name: "Priya",
    startingWeight: "55 Kg",
    currentWeight: "42 Kg",
    testimonial:
      "I’m deeply grateful to my coach for his exceptional guidance, unwavering support, and expert knowledge that made all the difference in my physique",
  },
  {
    src: Transformation_2,
    name: "Janet Irani",
    startingWeight: "94 kg",
    currentWeight: "58 Kg",
    testimonial:
      "With dedicated guidance and support from my coach, I transformed my life by losing weight, boosting my confidence, and becoming a better version of myself physically and mentally",
  },
  {
    src: Transformation_1,
    name: "Harsh Bhatnagar",
    startingWeight: "85 Kg",
    currentWeight: "72 Kg",
    testimonial:
      "This journey has transformed me with mental strength and self-belief, thanks to my coach's exceptional support and dedication",
  },
  {
    src: Transformation_13,
    name: "Chandni",
    startingWeight: "95 Kg",
    currentWeight: "68 Kg",
    testimonial:
      "From nearly 100 kgs to losing over 25 kgs in a year, my coach not only transformed my body but also healed my health—forever grateful for his unwavering support and guidance.",
  },
  {
    src: Transformation_3,
    name: "Toshit Tarun",
    startingWeight: "73 Kg",
    currentWeight: "61 Kg",
    testimonial:
      "He turned my struggles into success with unwavering support and a tailored plan",
  },
  {
    src: Transformation_9,
    name: "Sushree Srabani",
    startingWeight: "82 Kg",
    currentWeight: "63 Kg",
    testimonial:
      "From struggling with obesity to regaining strength and confidence. All thanks to my coach",
  },
  {
    src: Transformation_4,
    name: "Vinita Gupta",
    startingWeight: "86 Kg",
    currentWeight: "76 Kg",
    timeTaken: "4 months",
    testimonial:
      "Transforming from a foodie to 11 kg lighter was a journey made possible by my mentor's professional guidance and unwavering support, turning skepticism into motivation and change",
  },
  {
    src: Transformation_7,
    name: "Ritesh",
    startingWeight: "102 Kg",
    currentWeight: "76 Kg",
    testimonial:
      "Consistency, honesty, and expert guidance have transformed my health, stamina, and habits, making me stronger and more mindful every day.",
  },
  {
    src: Transformation_11,
    name: "Aditya",
    startingWeight: "106 Kg",
    currentWeight: "83 Kg",
    testimonial:
      "After gaining 20 kgs due to an accident and poor habits, I lost 26 kgs with the help of my coach, transforming my life",
  },
  {
    src: Transformation_14,
    name: "Deepak Gupta",
    startingWeight: "90 Kg",
    currentWeight: "82 Kg",
    testimonial:
      "Thanks to my superb coach, I've transformed my fitness journey from unhealthy weight loss to a disciplined and energized new me in just three months",
  },
  {
    src: Transformation_5,
    name: "Rashmi",
    startingWeight: "77.89 Kg",
    currentWeight: "62 Kg",
    timeTaken: "12 weeks",
    testimonial:
      "Lost 15kg and transformed from XXL to M in just 12 weeks, thanks to dedicated training and quantified nutrition—nothing worth having comes easy, and I'm incredibly grateful for the guidance",
  },
  {
    src: Transformation_17,
    name: "Rasika vaddy",
    startingWeight: "80 Kg",
    currentWeight: "63.3 Kg",
    testimonial:
      "After gaining 20 kgs due to an accident and poor habits, I lost 26 kgs with the help of my coach, transforming my life",
  },
  {
    src: Transformation_20,
    name: "Sant Kumar",
    startingWeight: "85 Kg",
    currentWeight: "70 Kg",
    testimonial:
      "Joining Adaptfit has been a game-changer for me. My coach’s personalized guidance and support transformed my fitness journey, allowing me to lose weight, boost energy, and improve my well-being while enjoying my favorite foods",
  },
  {
    src: Transformation_8,
    name: "Dhruv Javeri",
    startingWeight: "74 Kg",
    currentWeight: "74 Kg",
    testimonial:
      "Thanks to my coach's consistent guidance, I've overcome hurdles, lost fat, and gained crucial insights into muscle building and nutrition",
  },
  {
    src: Transformation_15,
    name: "Mahendra kumar",
    startingWeight: "97 Kg",
    currentWeight: "84 Kg",
    testimonial:
      "With expert guidance, motivation, and a balanced approach, I’ve transformed fitness from a task into a passion, thanks to my coach's customized plans and insightful support",
  },
  {
    src: Transformation_12,
    name: "Sneha Sharma",
    startingWeight: "83.4 Kg",
    currentWeight: "67 Kg",
    testimonial:
      "Thanks to an incredible mentor, I transformed from XXL to M, learned to create my own diet plan, and achieved the fitness I always dreamed of—all while enjoying lots of food!",
  },
  {
    src: Transformation_16,
    name: "Raka",
    startingWeight: "72 Kg",
    currentWeight: "60 Kg",
    testimonial:
      "Starting this journey transformed my life by breaking old habits, embracing new routines, and achieving real results with the guidance of my supportive coach",
  },
  {
    src: Transformation_21,
    name: "Yogesh Mange",
    startingWeight: "70 Kg",
    currentWeight: "63 Kg",
    testimonial:
      "Thanks to your dedication and expertise, I've lost weight, gained strength, and transformed my confidence and body image",
  },
];

export default gettingStartedImages;
