/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import TransformationsCarousel from "../components/GoogleAds/TransformationsCarousel";
import ChooseAdaptFit from "../components/GoogleAds/ChooseAdaptFit";
import StepByStepGuide from "../components/GoogleAds/StepByStepGuide";
import Contact from "./contact";
import { motion } from "framer-motion";
import Coaches from "../pages/coaches";
import { useInView } from "react-intersection-observer";
import MoneyBackImg from "../images/money_back.PNG";
import ImgCustomizeHealth from "../images/CustomHealth.JPG";
import ImgAchieveGoals from "../images/achieve_goals.png";
import { FaRocket } from "react-icons/fa";

function GoogleAdvertisements() {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const targetRef = useRef(null);
  const StartupTransformation = () => {
    return (
      <>
        <div ref={targetRef} className="bg-black text-white text-center p-2">
          <h1 className="text-4xl text-white font-medium sm:text-3xl">
            <p className="flex items-center justify-center text-white font-bold py-2 px-4 gap-4">
              <FaRocket />
              Start Your Transformation Today
            </p>
          </h1>
          <Contact isFromGoogleAdd={true} />
          <div className="mt-12 flex justify-center">
            <br />
            <img
              src={MoneyBackImg}
              alt="Money Back Guarantee"
              className="h-40 w-50"
            ></img>
          </div>
          <h3 className="mt-5 text-2xl font-bold">
            30-Day Money-Back Guarantee
          </h3>
          <p className="mt-4">
            At AdaptFit, we are committed to helping you achieve your health and
            fitness goals.
          </p>
          <p>
            We are confident in the effectiveness of our personalized fitness
            and nutrition plans, but if you are not satisfied with your progress
            within the first 30 days, we offer a full money-back guarantee. No
            questions asked.
          </p>
          <button
            onClick={() => RedirectToPricing()}
            className="mt-10 bg-green-500 hover:bg-green-700 text-text font-bold py-2 px-4 rounded-full"
          >
            <p className="flex justify-center text-center text-2xl text-black">
              <MdRemoveRedEye size={35} /> VIEW PLANS
            </p>
          </button>
        </div>
      </>
    );
  };

  const RedirectToPricing = () => {
    window.location.href = "/pricing";
  };

  const ScrollToDiv = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const CustomizeHealth = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${ImgCustomizeHealth})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
        }}
        className="flex justify-center items-center bg-black text-white px-4 h-96 md:h-400"
      >
        <div className="flex w-1/2 md:w-full flex-col justify-center items-center space-y-6 h-64 md:h-96 px-2">
          <motion.h1
            className="text-5xl md:text-4xl font-bold bg-gradient-to-r from-green-300 to-green-500 inline-block text-transparent bg-clip-text"
            initial={{ opacity: 0, y: -50 }}
            animate={inView1 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            <span className="bg-gradient-to-r from-gray-300 to-white inline-block text-transparent bg-clip-text">
              Customized Health & Fitness
              <br />
              Plans To Fit Your Busy Life
            </span>{" "}
          </motion.h1>
          <motion.p
            className="text-white text-base"
            initial={{ opacity: 0, y: 50 }}
            animate={inView1 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="flex items-center justify-center">
              <img
                src={ImgAchieveGoals}
                alt="achieve fitness goals"
                className="w-10 h-10 md:w-6 md:h-6 mr-2"
              />
              <p className="text-xl md:text-lg">
                Achieve Your Fitness Goals with Personalized Plans and Expert
                Support
              </p>
            </div>
          </motion.p>
          <div className="flex space-x-4">
            <motion.button
              className="bg-green-500 text-black py-3 px-6 rounded-full font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              ref={ref1}
              onClick={ScrollToDiv}
            >
              Claim Free Consultation
            </motion.button>
          </div>
          <br />
        </div>
      </div>
    );
  };

  const HealthPlans = () => {
    return (
      <div className="space-y-8 flex flex-col items-center p-4 mb-8">
        <h1 className="text-4xl text-black font-medium sm:text-3xl">
          <p className="flex items-center justify-center text-black gap-1 font-bold py-2 px-4 rounded-3xl mt-4">
            <img
              src="./health_condition.png"
              alt="claim consultation"
              className="w-12 h-12 sm:w-10 sm:h-10 mr-2"
            />
            Tailored Plans
          </p>
          <p className="text-lg text-center">For Specific Health Conditions</p>
        </h1>

        <div className="flex gap-8 flex-col items-center p-4 w-full md:w-1/2 lg:w-1/3">
          <div className="border-2 border-black p-4 relative text-center w-96 h-35">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 flex items-center">
              <img src="./thyroid.png" alt="thyroid" className="w-6 h-6 mr-2" />{" "}
              <span className="font-semibold">Thyroid Issues</span>
            </div>

            <p className="mt-5">
              Boost your metabolism with thyroid friendly fitness plans.
            </p>
          </div>

          <div className="border-2 border-black p-4 relative text-center w-96 h-35">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 flex items-center">
              <img src="./pcod.png" alt="pcod" className="w-10 h-12 mr-2" />{" "}
              <span className="font-semibold">PCOD</span>
            </div>

            <p className="mt-5">
              Reduce symptoms with targeted exercise and dietary guidance.
            </p>
          </div>

          <div className="border-2 border-black p-4 relative text-center w-96 h-35">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 flex items-center">
              <img
                src="./diabetes.png"
                alt="diabetes"
                className="w-8 h-12 mr-2"
              />{" "}
              <span className="font-semibold">Diabetes</span>
            </div>
            <p className="mt-5">
              Manage your blood sugar levels with customized fitness and
              nutrition plans.
            </p>
          </div>

          <div className="border-2 border-black p-4 relative text-center w-96 h-35">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 flex items-center">
              <img
                src="./high BP.png"
                alt="high-bp"
                className="w-8 h-12 mr-2"
              />{" "}
              <span className="font-semibold">High BP</span>
            </div>
            <p className="mt-5">
              Lower your blood pressure naturally with cardiovascular health
              focused routines.
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Initialize Google Analytics

  return (
    <>
      <div>{CustomizeHealth()}</div>
      <TransformationsCarousel />
      <div className="mt-10" />
      <ChooseAdaptFit />
      <div className="mt-10" />
      <StepByStepGuide />
      <div>{HealthPlans()}</div>
      <Coaches isFromGoogleAdd={true} />
      <div>{StartupTransformation()}</div>
    </>
  );
}

export default GoogleAdvertisements;
