import React, { useState, useEffect } from "react";
import {
  ArrowForward,
  ArrowRight,
} from "../components/HeroSection/HeroElements";
import { Button } from "../components/ButtonElements";
import { useHistory } from "react-router-dom";

const Policy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover((prev) => !prev);
  };
  // Initialize Google Analytics

  return (
    <>
      <h1
        style={{
          backgroundImage: "url('/policy.png')",
        }}
        className="bg-cover font-bold h-40 bg-no-repeat bg-center text-white flex justify-center p-5 text-6xl uppercase items-center tracking-wider text-center md:text-4xl"
      >
        Privacy Policy
      </h1>
      <div className="m-5 p-3 border-r-2 shadow-md">
        We care about data privacy and security. Please review our Privacy
        Policy at https://www.adaptfit.com/privacy. By using the Website/Mobile
        Application, you agree to be bound by our Privacy Policy, which is
        incorporated into these Terms and Conditions. Please be advised the
        Website/Mobile Application is hosted in EU Region as per the GDPR
        Guidelines. If you access the Website/Mobile Application from any other
        region of the world with laws or other requirements governing personal
        data collection, use, or disclosure that differ from applicable laws in
        India or EU, then through your continued use of the Website/Mobile
        Application, you are transferring your data to India and EU, and you
        expressly consent to have your data transferred to and processed in
        India and stored in EU region. Further, we do not knowingly accept,
        request, or solicit information from children or knowingly market to
        children. If we receive actual knowledge that anyone under the age of 18
        has provided personal information to us without the requisite and
        verifiable parental consent, we will delete that information from the
        Website/Mobile Application as quickly as is reasonably practicable.
        COPYRIGHT INFRINGEMENTS We respect the intellectual property rights of
        others. If you believe that any material available on or through the
        Website/Mobile Application infringes upon any copyright you own or
        control, please immediately notify us using the contact information
        provided below (a “Notification”). A copy of your Notification will be
        sent to the person who posted or stored the material addressed in the
        Notification. Please be advised that pursuant to Indian Copyright laws
        you may be held liable for damages if you make material
        misrepresentations in a Notification by making false copyright
        claims.Thus, if you are not sure that material located on or linked to
        by the Website/Mobile Application infringes your copyright, you should
        consider first contacting an attorney. TERM AND TERMINATION These Terms
        and Conditions shall remain in full force and effect while you use the
        Website/Mobile Application. WITHOUT LIMITING ANY OTHER PROVISION OF
        THESE TERMS AND CONDITION, WE RESERVE THE RIGHT TO, IN OUR SOLE
        DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF
        THE WEBSITE/MOBILE APPLICATION (INCLUDING BLOCKING CERTAIN IP
        ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
        WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
        COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE
        LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
        Website/MOBILE APPLICATION OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
        INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
        DISCRETION. If we terminate or suspend your account for any reason, you
        are prohibited from registering and creating a new account under your
        name, a fake or borrowed name, or the name of any third party, even if
        you may be acting on behalf of the third party. In addition to
        terminating or suspending your account, we reserve the right to take
        appropriate legal action, including without limitation pursuing civil,
        criminal, and injunctive redress. MODIFICATIONS AND INTERRUPTIONS We
        reserve the right to change, modify, or remove the contents of the
        Website/Mobile Application at any time or for any reason at our sole
        discretion without notice. However, we have no obligation to update any
        information on our Website/Mobile Application. We also reserve the right
        to modify or discontinue all or part of the Website/Mobile Application
        without notice at any time. We will not be liable to you or any third
        party for any modification, price change, suspension, or discontinuance
        of the Website/Mobile Application. We cannot guarantee the
        Website/Mobile Application will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the Website/Mobile Application, resulting in
        interruptions, delays, or errors. We reserve the right to change,
        revise, update, suspend, discontinue, or otherwise modify the
        Website/Mobile Application at any time or for any reason without notice
        to you. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the
        Website/Mobile Application during any downtime or discontinuance of the
        Website/Mobile Application. Nothing in these Terms and Conditions will
        be construed to obligate us to maintain and support the Website/Mobile
        Application or to supply any corrections, updates, or releases in
        connection therewith. GOVERNING LAW * These Terms and Conditions and
        your use of the Website/Mobile Application shall be governed by and
        shall be construed in accordance with the laws of India. All disputes
        relating to this Agreement shall be settled in the courts located at
        Kolkata ,West Bengal. * Any cause of action arising out of a User’s use
        of Services must be commenced within 30 (thirty) days after: * A) when
        such cause of action accrues; or b) such User becomes aware of the facts
        giving rise to the cause of action, whichever is later, else, such cause
        of action shall be permanently barred. DISPUTE RESOLUTION Binding
        Arbitration If any dispute, controversy or claim arises under this
        Agreement or in relation to any adaptfit service or the adaptfit
        platform, including any question regarding the existence, validity or
        termination of this Agreement or T&Cs (hereinafter Dispute), the parties
        shall use all reasonable endeavours to resolve such Dispute amicably. If
        the parties are unable to resolve the Dispute amicably within 30 days of
        the notice of such Dispute, adaptfit may elect to resolve any Dispute by
        a binding arbitration in accordance with the provisions of the Indian
        Arbitration & Conciliation Act, 1996 (hereinafter Act). Such Dispute
        shall be arbitrated on an individual basis and shall not be consolidated
        in any arbitration with any claim or controversy of any other party. The
        Dispute shall be resolved by a sole arbitrator, appointed in accordance
        with the Act. The seat of the arbitration shall be Kolkata and the
        language of this arbitration shall be English. Either You or adaptfit
        may seek any interim or preliminary relief from a court of competent
        jurisdiction in Kolkata necessary to protect the rights or the property
        belonging to You or adaptfit (or any of our agents, suppliers, and
        subcontractors,employees), pending the completion of arbitration. Any
        arbitration shall be confidential, and neither You nor adaptfit may
        disclose the existence, content or results of any arbitration, except as
        may be required by law or for purposes of enforcing the arbitration
        award. All administrative fees and expenses of arbitration will be
        divided equally between You and adaptfit. In all arbitrations, each
        party will bear the expense of its own lawyers and preparation. This
        paragraph shall survive termination of this Agreement. RESTRICTIONS The
        Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law, *
        No arbitration shall be joined with any other proceeding; * There is no
        right or authority for any Dispute to be arbitrated on a class-action
        basis or to utilize class action procedures; and * There is no right or
        authority for any Dispute to be brought in a purported representative
        capacity on behalf of the general public or any other persons EXCEPTIONS
        TO ARBITRATION The Parties agree that the following Disputes are not
        subject to the above provisions concerning binding arbitration: * Any
        Disputes seeking to enforce or protect, or concerning the validity of,
        any of the intellectual property rights of a Party; * Any Dispute
        related to, or arising from, allegations of theft, piracy, invasion of
        privacy, or unauthorized use; and * Any claim for injunctive relief. If
        this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of
        this provision found to be illegal or unenforceable and such Dispute
        shall be decided by a court of competent jurisdiction within the courts
        listed for jurisdiction above, and the Parties agree to submit to the
        personal jurisdiction of that court. CORRECTIONS There may be
        information on the Website/Mobile Application that contains
        typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other information. We
        reserve the right to correct any errors, inaccuracies, or omissions and
        to change or update the information on the Website/Mobile Application at
        any time, without prior notice. DISCLAIMER THE WEBSITE/MOBILE
        APPLICATION IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
        THAT YOUR USE OF THE WEBSITE/MOBILE APPLICATION AND OUR SERVICES WILL BE
        AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
        ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
        WEBSITE/MOBILE APPLICATION AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE WEBSITE/MOBILE
        APPLICATION’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE
        WEBSITE/MOBILE APPLICATION AND WE WILL ASSUME NO LIABILITY OR
        RESPONSIBILITY FOR ANY: * ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
        AND MATERIALS, * PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
        WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE WEBSITE, * ANY
        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, * ANY
        INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE/MOBILE
        APPLICATION, * ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
        BE TRANSMITTED TO OR THROUGH THE WEBSITE/MOBILE APPLICATION BY ANY THIRD
        PARTY, AND/OR * ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
        FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
        ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
        WEBSITE/MOBILE APPLICATION. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
        ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
        BY A THIRD PARTY THROUGH THE WEBSITE/MOBILE APPLICATION, ANY HYPERLINKED
        WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
        OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        LIMITATIONS OF LIABILITY In consideration of being allowed to
        participate in the activities and structured diet programs offered by
        adaptfit and to use its structured diet programs and training schedule,
        in addition to the payment of any fee or charge, you or any third party
        do hereby waive, release and forever discharge and hold harmless
        adaptfit , its partners/directors, consultants, officers, agents, and
        employees from any and all responsibility, liability, cost and expenses,
        including injuries or any direct, indirect, consequential, exemplary,
        incidental, special, or punitive damages, including lost profit, lost
        revenue, loss of data, or other damages, resulting from your
        participation in any activities, or your use of any structured diet
        programs designed by adaptfit. You do also hereby release adaptfit, its
        directors or partners, consultants, officers, agents and employees from
        any responsibility or liability for any injury, damage or disorder
        (physical, metabolic, or otherwise) to yourself, or in any way arising
        out of or connected with your participation in any activities with
        adaptfit. You understand and you are aware that strength, flexibility,
        and aerobic exercise, including the use of equipment are a potentially
        hazardous activity. You also understand that fitness activities involve
        a risk of injury and even death, and that you are voluntarily
        participating in these activities and using equipment and machinery with
        knowledge of the risk involved. You hereby agree to expressly assume and
        accept any and all risks of injury or death related to said fitness
        activities. In addition, You certify that you are 18 years of age or
        older. You do hereby further declare yourself to be physically sound and
        suffering from no condition, impairment, disease, infirmity or other
        illness that would affect nutrient metabolism or prevent your
        participation or use of equipment or machinery except as hereinafter
        stated. And You also agree that if you are suffering from any ailment or
        any medical condition you have to inform and produce relevant documents
        to adaptfit prior to signing of any document or agreement. You do hereby
        acknowledge that adaptfit has recommended to you to obtain a physician’s
        approval for your participation in an exercise/fitness activity or in
        the use of exercise equipment and machinery. You also acknowledge that
        adaptfit has recommended that you have a yearly or more frequent
        physical examination and consultation with your physician as to physical
        activity, exercise and use of exercise and training equipment so that
        you might have his/her recommendations concerning these fitness
        activities and use of equipment. You acknowledge that you have either
        had a physical examination and been given your physician’s permission to
        participate, or that you have decided to participate in activity and use
        of equipment, machinery, and programs designed by adaptfit without the
        approval of your physician and do hereby assume all responsibility for
        your participation and activities, and utilization of equipment and
        machinery in your activities. adaptfit diet/training programs are not
        meant to treat or manage any health condition. Always consult with your
        healthcare provider prior to adjusting your current style of eating or
        beginning any new diet and/or training plan. You understand that you are
        agreeing to the terms of the Company that is adaptfit having known that
        beforehand and understand that not following the instructions and
        structured diet programs entirely and regularly given by adaptfit will
        not produce 100% result. IN NO EVENT WILL WE OR OUR DIRECTORS/PARTNERS
        EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
        DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
        DAMAGES ARISING FROM YOUR USE OF THE WEBSITE/MOBILE APPLICATION, EVEN IF
        WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. INDEMNIFICATION
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: * Your Contributions;
        * Use of the Website/Mobile Application * Breach of these Terms and
        Conditions; * Any breach of your representations and warranties set
        forth in these Terms and Conditions; * Your violation of the rights of a
        third party, including but not limited to intellectual property rights;
        or * Any overt harmful act toward any other user of the Website/Mobile
        Application with whom you connected via the Website/Mobile Application.
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it. USER DATA We will
        maintain certain data that you transmit to the Website/Mobile
        Application for the purpose of managing the performance of the
        Website/Mobile Application, as well as data relating to your use of the
        Website/Mobile Application. Although we encrypt and perform regular
        routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using
        the Website/Mobile Application. You agree that we shall have no
        liability to you for any loss or corruption of any such data, and you
        hereby waive any right of action against us arising from any such loss
        or corruption of such data. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
        SIGNATURES Visiting the Website/Mobile Application, sending us emails,
        and completing online forms constitute electronic communications. You
        consent to receive electronic communications, and you agree that all
        agreements, notices, disclosures, and other communications we provide to
        you electronically, via email and on the Website/Mobile Application,
        satisfy any legal requirement that such communication be in writing. YOU
        HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
        OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
        RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
        WEBSITE/MOBILE APPLICATION. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means MISCELLANEOUS These
        Terms and Conditions and any policies or operating rules posted by us on
        the Website/Mobile Application or in respect to the Website/Mobile
        Application constitute the entire agreement and understanding between
        you and us. Our failure to exercise or enforce any right or provision of
        these Terms and Conditions shall not operate as a waiver of such right
        or provision. These Terms and Conditions operate to the fullest extent
        permissible by law. We may assign any or all of our rights and
        obligations to others at any time. We shall not be responsible or liable
        for any loss, damage, delay, or failure to act caused by any cause
        beyond our reasonable control. If any provision or part of a provision
        of these Terms and Conditions is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed
        severable from these Terms and Conditions and does not affect the
        validity and enforceability of any remaining provisions. There is no
        joint venture, partnership, employment or agency relationship created
        between you and us as a result of these Terms and Conditions or use of
        the Website/Mobile Application. You agree that these Terms and
        Conditions will not be construed against us by virtue of having drafted
        them. You hereby waive any and all defenses you may have based on the
        electronic form of these Terms and Conditions and the lack of signing by
        the parties hereto to execute these Terms and Conditions. CONTACT US In
        order to resolve a complaint regarding the Website or to receive further
        information regarding the use of the Website, please contact us at:
        adaptfit.in@gmail.com
        <div className="m-4">
          <Button
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            onClick={() => history.push("/")}
          >
            Accept & Go Back {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Policy;
