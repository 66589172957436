import React, { useEffect } from "react";

const FAQ = () => {
  const toggleClass = (questionNumber) => {
    const answers = document.querySelectorAll("#answer");
    answers[questionNumber].classList.toggle("hidden");
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Initialize Google Analytics

  return (
    <>
      <h1
        style={{
          backgroundImage: "url('/faq.png')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="font-bold h-40 text-white flex justify-center p-5 text-6xl uppercase items-center tracking-wider text-center md:text-4xl"
      >
        FAQ
      </h1>
      <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Frequently Asked Questions
            </h2>
            <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Find answers to questions we always receive.If your query is not
              there, send us a message! We’re here to help.
            </p>
          </div>

          <div class="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(0)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  How does the online program work?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400 rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" className="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  I will craft a personalized diet plan and workout regimen
                  tailored to your lifestyle and fitness objectives. Your
                  progress will be closely monitored, ensuring that you reach
                  your goals and observe tangible results. Weekly update calls
                  will keep us connected, allowing us to evaluate your
                  advancements and adapt your diet plan as needed. <br />
                  <br />
                  To make the workout plan more accessible, I'll provide video
                  links, ensuring you have a clear understanding of each
                  exercise. Occasionally, I may request workout videos from you
                  to confirm your proper form and technique.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(1)}
              >
                <span class="flex sm:w-72 sm:p-2 text-lg font-semibold text-black">
                  How much weight can I lose and how much muscle can I gain
                  during the program?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  The transformation of each individual is influenced by a
                  multitude of factors, including genetics, lifestyle, dietary
                  history, existing medical conditions, and various other
                  elements. We are deeply committed to guiding you on your
                  journey, yet the most vital ingredient is your dedication to
                  the process. <br />
                  <br />
                  While no one can promise guaranteed results, we can certainly
                  guarantee that if you wholeheartedly commit to following your
                  coach's guidance, you will undoubtedly witness significant
                  improvements from your starting point. <br />
                  <br />
                  It's important to recognize that the scale doesn't tell the
                  whole story; kilogram weight loss is just one of several
                  markers of progress. Inch loss, enhanced sleep quality,
                  increased strength and stamina, and a healthier relationship
                  with food all serve as important indicators of your
                  advancement.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <div class="">
                <button
                  type="button"
                  class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                  onClick={() => toggleClass(2)}
                >
                  <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                    Is it safe to follow a diet and workout program when i have
                    some medical issue?
                  </span>

                  <svg
                    class="w-6 h-6 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                  <p>
                    The suitability of any diet or fitness program depends on
                    your specific medical conditions, and it's always imperative
                    to seek approval from your physician before commencing any
                    such regimen. Rest assured, I will carefully consider all
                    your medical aspects as I provide guidance and support
                    throughout your journey. Your well-being is my top priority.
                  </p>
                </div>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(3)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Can I take a medical break in the program?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Yes. During the program, you have the option to take a medical
                  break for up to one month. If you need to do this, just send
                  your medical certificates or reports.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(4)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  What should you have ready so that you can begin right away
                  when I send you the diet plan?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  To get started, you'll need a few basic tools:
                  <br /> <br />
                  A food scale for portion control, a body weight scale to track
                  your progress, and a measuring tape to measure changes in your
                  body. <br /> <br />
                  If you plan to work out at home, you'll also want a pair of
                  adjustable dumbbells and a resistance tube to help with your
                  exercise routine. These items will make your fitness journey
                  more convenient and effective.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(5)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  How will my exercise form be corrected?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Your form videos are reviewed and detailed feedback is
                  provided - this is part of Coaching Service.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(6)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Can I pay monthly instead of one time payment?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  We only accept one time payments to keep coaching hassle free
                  for both of us. Some banks/cards might provide an option of
                  EMI to you on payment gateway, that you will have to check.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(7)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Does the partner plan cater to our distinct fitness goals i.e.
                  separate meal and workout plans for us per our fitness
                  objectives - given I enroll with a friend/partner?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Yes of course, when you enroll with a partner plan - both of
                  you would be treated as individuals, and separate
                  plans/communications will follow. Buddy plan is simply an
                  added discount if you enroll with a friend/partner.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(8)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Will my injuries/health conditions be taken care of in plan?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Yes, your training and diet plans will take care of your
                  unique constraints/injuries/health conditions. All your
                  personal details will be taken in the coaching application.
                  However, we are not medical professionals and please be sure
                  to work under your doctor's instructions.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(9)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Which is the best coaching plan for me? 3 or 6 month?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  The time interval is upto you to decide. To produce meaningful
                  results- we need at least 3 months of time. Best
                  Transformations usually take 6 months or longer. Ultimately,
                  you are stepping on a lifelong journey, and we are only here
                  to save you time and accelerate your learning curve. Longer
                  time frame makes coaching cost effective on a per month basis,
                  has a higher commitment going on for client, and hence - high
                  success rate.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(10)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  What kind of equipment will I need?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  I create the program based on the equipment you have
                  available. However, it is strongly recommended to have a gym
                  membership or dumbbells at home because we always aim to
                  challenge the body. My programming can be done either at home
                  or at the gym, complementing any other plans you may already
                  have in place.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(11)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Can I do the plan at home?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Yes, absolutely! I will customize your plan so you can do it
                  from the comfort of your home if you prefer.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(12)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  How can I contact adaptfit?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  You can mail us at in &nbsp;
                  <a
                    href="mailto:adaptfit.in@gmail.com?body=I have few doubts related to program"
                    title=""
                    class="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
                  >
                    adaptfit.in@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <p class="text-center text-gray-600 textbase mt-9">
            Didn’t find the answer you are looking for?
            <a
              href="https://wa.me/919004183595?text=Hey%2C%20I'd%20love%20to%20join%20you%20on%20this%20fitness%20journey!"
              title=""
              class="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
            >
              Contact Us
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default FAQ;
