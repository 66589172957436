import React from "react";
import { FaDumbbell, FaStopwatch, FaWeight } from "react-icons/fa";

const TransformationBadge = ({ startingWeight, currentWeight, timeTaken }) => {
  return (
    <div className="flex justify-center items-center flex-wrap">
      {startingWeight && (
        <div
          style={{ padding: "3px" }}
          className={`flex items-center text-white rounded-lg bg-black h-10 w-30 m-2`}
        >
          <FaWeight style={{ marginRight: "4px" }} className="text-sm" />
          <div>
            <p className="text-sm font-bold">
              Before:
              <span className="text-sm font-semibold"> {startingWeight}</span>
            </p>
          </div>
        </div>
      )}
      {currentWeight && (
        <div
          style={{ padding: "4px" }}
          className={`flex items-center text-white bg-green-500 rounded-lg h-10 w-30 m-2`}
        >
          <FaDumbbell style={{ marginRight: "4px" }} className="text-sm mr-2" />
          <div>
            <p className="text-sm font-bold">
              After:
              <span className="text-sm font-semibold"> {currentWeight}</span>
            </p>
          </div>
        </div>
      )}
      {timeTaken && (
        <div
          style={{ backgroundColor: "rgb(243,239,229)", padding: "4px" }}
          className={`flex items-center text-black rounded-lg h-10 m-2`}
        >
          <FaStopwatch
            style={{ marginRight: "4px" }}
            className="text-sm mr-2"
          />
          <div>
            <p className="text-sm font-bold">
              Time Taken:
              <span className="text-md font-semibold"> {timeTaken}</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransformationBadge;
