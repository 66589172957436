import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowDown } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";
import images from "../../images/transformations/index";

const TransformationsCarousel = () => {
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: "10px",
          zIndex: 1,
          cursor: "pointer",
          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right text-white"></i>
      </div>
    );
  };

  // Custom previous arrow component
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "10px",
          zIndex: 1,
          cursor: "pointer",
          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left text-white"></i>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 3000, // Time in milliseconds between slides
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="p-2">
      <h1 className="text-4xl text-black font-medium sm:text-3xl">
        <p className="flex items-center justify-center text-black gap-1 font-bold py-2 px-4 rounded-3xl mt-4">
          <IoMdCheckmarkCircle />
          See Real Results
        </p>
        <p className="text-lg text-center">From Real People - Just like you!</p>
      </h1>
      <div
        id="transformationWidth"
        style={{ width: "80%" }}
        className="rounded-lg shadow-lg mx-auto bg-white mb-2 md:w-full"
      >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.src}
                alt="Transformation Images"
                className="object-cover w-full rounded-md"
                style={{ height: "100%", width: "100%" }}
              />
              <div className="text-center mt-6">
                <h2 className="text-2xl font-bold my-1">{image.name}</h2>
                <br />
                <div className="text-xl flex justify-center font-bold">
                  <span
                    style={{
                      width: "80%",
                      background: "linear-gradient(to left,#1cae6e, #007542)",
                    }}
                    className="flex justify-center items-center rounded-lg text-white font-semibold py-2 px-4 shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl"
                  >
                    {parseFloat(image.startingWeight) ===
                    parseFloat(image.currentWeight) ? (
                      "Body Recomposition"
                    ) : (
                      <>
                        <FaArrowDown className="py-1" />
                        <span className="mx-2">
                          {Math.abs(
                            parseFloat(image.startingWeight) -
                              parseFloat(image.currentWeight)
                          ).toFixed(0)}
                          KG WEIGHT LOSS
                        </span>
                        <FaArrowDown className="py-1" />
                      </>
                    )}
                  </span>
                </div>
                <br />
                <p style={{ fontStyle: "italic" }} className="text-sm my-1">
                  {image.testimonial}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TransformationsCarousel;
