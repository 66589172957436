export const reviews = [
  {
    id: 1,
    username: "Ruturaj Birje",
    rating: 5,
    review:
      "I have trained with Dushyant for A Quarter. He is very knowledgeable and understands body type properly to guide in order to meet my expectations in terms of growth I want. Workout split and measuring ways were very simple and effective.Will surely recommend Dushyant for personal training and nutrition tips. Cheers 👍🏻",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjUvcU6JE9Q9ER-HF16OzxWLiTECAYZ6KYJ7m2Ayba2KnHnE73TVgg=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/5fisGti",
  },
  {
    id: 2,
    username: "Mayur Karsadiye",
    rating: 5,
    review:
      "I read a lot of positive reviews about dushyant and I knew I was in good hands. he is not very pushy but very happy go lucky and makes me understand the science behind the diet and workout. he has been very helpful regarding all the questions we had during the 3 months. he is very patient and we never felt hurried when asking questions to him. ",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjXf4hnkKsQ2fZJ1iw2G38m5usEnDMO1Ky36RcScJaJ4Esf5YpfMIg=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/fi8DwNm",
  },
  {
    id: 3,
    username: "Saurav Ingle",
    rating: 5,
    review:
      "Dushyant's thorough approach and clear explanations were instrumental in guiding me through my fitness journey. Over the course of my three-month partnership, he helped me understand the mechanics of my body and identify areas where my habits, especially in diet, needed adjustment. Dushyant patiently discussed every aspect, from cheat meals to daily nutrition, and provided actionable steps for improvement.",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjV4rNGqfbEs-IdLV7KnY_9jzqmfU05VC1pP8s0KvYK36veh-ewQBQ=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/1xX4EEX",
  },
  {
    id: 4,
    username: "Aadil khan",
    rating: 5,
    review:
      "Dushyant is excellent trainer and mentor. He ensure the plan is followed and even if you miss at instance, he pushes to get back on track. Balance diet and exercise becomes part of your life with his guidance. He answers all your doubts and explain science behind your healthy journey. With his knowledge you understand what to eat while partying.",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjUE5pogF0M6AEsV0DAhA6_Y5C-4Q8MRpsPgtzWuxlRJgSCM49Sq=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/J9r9Nva",
  },
  {
    id: 5,
    username: "sakshi atkar",
    rating: 5,
    review:
      "Dushyant has been an amazing coach through these 3 months. I was stuck at 60 kg and couldn't go below that weight to achieve my goal/healthy weight. Through his constant guidance and support, I have been able to achieve my goal and reached my healthy weight. He was very patient and very helpful whenever we had to adjust meal plans or needed any other guidance.",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjVFL483aHlL1f8e_u80fOQiu-ws-Qs5CDa1F15LAize0nsjdU3y=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/5NhtsA1",
  },
  {
    id: 6,
    username: "Jigar Patel",
    rating: 5,
    review:
      "It was one of the best decision that taken in my life, little late but worth and most valuable in return. Thank you so much Dushyant sir for sharing correct information and showing right path to achieve as decided target in within duration. Guys go for it without any doubt in mind and check out best version of yourself.#Fit India",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjXyVhemteFYI-qsSn1VrWZgwnedtIhShApq1hhR2YgYJyfvicw=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/DQKBbNw",
  },
  {
    id: 7,
    username: "ankit ranjan",
    rating: 5,
    review:
      "I read lot of reviews on adaptfit and decided to go with the diet.But I knew that I wanted a trainer because I didn't want to do any experiments with my body. When I spoke to dushyant, he seemed to be lenient, vocal, good listener and always happy to help kinda person. Also he had experience in managing weight loss, so thought he would be proficient in this area. So I decided to begin with him.",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjVfLJnWGDN_ZAxnVAFc0X2WQGH-mtr5BKrBBdAhbwvp4Z1d1p0=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/rqSaSDw",
  },
  {
    id: 8,
    username: "ANAND NAIR",
    rating: 5,
    review:
      "Dushyant sir has been amazing. He guides with proper reasoning behind everything. He give personal space and at the same time keep on motivating. He just doesn't give advices that'll help you in short term but also have bigger picture in his mind",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjWLumqN0CmQeVBBcQFAM_ZCsFYael40XqG6mqALpoNBb5l7Ys6u=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/n6Lwj7D",
  },
  {
    id: 9,
    username: "Jinal Patel",
    rating: 5,
    review:
      "I was trained by Dushyant last year , was a beginner in lifting weights , weight training machines and barbless would scare me off. Was just happy doing cardio, Dushyant not only made me understand the benefits of the strength training but also allocated time to customize training and diet plan that works best for me. would 100% recommend him.",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjXVlL2t_cWolKF9NeJ2p3SZvXS7PnVxiyr_uxr9nqmQr4Aa8r6mew=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/MYoLMWz",
  },
  {
    id: 10,
    username: "Sheetal Vishwakarma",
    rating: 5,
    review:
      "Dushyant is a great coach, very outgoing, and knows how to keep the diet & workout fun but productive. I was able to learn many new things and get healthy.He is great at structuring the workouts for my goals and is incredibly friendly and helpful.I highly recommend dushyant as a trainer for all ages and skill levels.",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjVLGhwO3cTP_TzjHs70DqtBY8z9ioQXAMTEZO_LS_tylaC-eqpe=w132-h132-p-rp-mo-br100",
    ratingLink: "https://g.co/kgs/SDh15Cx",
  },
];
