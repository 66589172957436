import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        backgroundImage: "url('/notfound.jpg')",
        backgroundPosition: "top",
        backgroundSize: "40%",
      }}
      className="flex flex-col items-center justify-center gap-2 h-screen w-full"
    >
      <h1 className="text-6xl text-center font-bold text-green-500 mb-4">
        404
      </h1>
      <div className="flex flex-col justify-center items-center">
        <p
          style={{ background: "gray" }}
          className="text-2xl text-black mb-8 px-2 rounded"
        >
          Page Not Found
        </p>
        <a
          href="/"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Go to Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
