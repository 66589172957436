import React from "react";

import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import { FaEnvelope, FaInstagram, FaPhone, FaWhatsapp } from "react-icons/fa";
import logo from "../../images/logo.png";

function Footer() {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  // Initialize Google Analytics

  return (
    <FooterContainer>
      <FooterWrap>
        <div className="flex mt-2">
          <a
            href="tel:9004183595"
            className="flex gap-2 items-center text-white no-underline mb-2 text-sm hover:text-green-500 hover:transition hover:duration-300 hover:ease-out"
          >
            <FaPhone style={{ rotate: "90deg" }} /> <span>+91 9004183595</span>
          </a>
        </div>
        <div className="flex mt-2">
          <a
            href="mailto:adaptfit.in@gmail.com"
            className="flex gap-2 items-center text-white no-underline mb-2 text-sm hover:text-green-500 hover:transition hover:duration-300 hover:ease-out"
          >
            <FaEnvelope /> <span>adaptfit.in@gmail.com</span>
          </a>
        </div>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Legal</FooterLinkTitle>
              <FooterLink to="/terms">Terms & Conditions</FooterLink>
              <FooterLink to="/policy">Privacy Policy</FooterLink>
              <FooterLink to="/disclaimer">Disclaimer</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Help</FooterLinkTitle>
              <FooterLink to="/faq">FAQ</FooterLink>
              <FooterLink to="/contact">Contact</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Quick Link</FooterLinkTitle>
              <a
                className="text-white no-underline mb-2 text-sm hover:text-green-500 hover:transition hover:duration-300 hover:ease-out"
                href="https://g.co/kgs/K6GYwe2"
              >
                Reviews
              </a>
              <FooterLink to="/pricing">Enroll</FooterLink>
              <FooterLink to="/coaches">Coaches</FooterLink>
              <FooterLink to="/transformation">Transformations</FooterLink>
            </FooterLinkItems>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Connect</FooterLinkTitle>
                <div className="flex gap-2">
                  <SocialIconLink href="https://www.instagram.com/dushyantshahane?igsh=OGQ5ZDc2ODk2ZA==">
                    <FaInstagram />
                  </SocialIconLink>
                  <SocialIconLink href="https://wa.me/919004183595?text=Hey%2C%20I'd%20love%20to%20join%20you%20on%20this%20fitness%20journey!">
                    <FaWhatsapp />
                  </SocialIconLink>
                </div>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <img alt="logo" src={logo} height={120} width={120} />
            </SocialLogo>
            <WebsiteRights>
              adaptfit &copy; {new Date().getFullYear()} All Rights Reserved.
            </WebsiteRights>

            <SocialIcons>
              <SocialIconLink
                href="https://www.instagram.com/dushyantshahane?igsh=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://wa.me/919004183595?text=Hey%2C%20I'd%20love%20to%20join%20you%20on%20this%20fitness%20journey!"
                target="_blank"
                aria-label="Whatsapp"
              >
                <FaWhatsapp />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
