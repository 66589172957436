import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../images/transformations/index";

// Custom next arrow component
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "10px",
        zIndex: 1,
        cursor: "pointer",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-right text-white"></i>
    </div>
  );
};

// Custom previous arrow component
const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "10px",
        zIndex: 1,
        cursor: "pointer",
        borderRadius: "50%",
      }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-left text-white"></i>
    </div>
  );
};

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // Initialize Google Analytics

  return (
    <div
      style={{
        maxWidth: "315px",
        margin: "auto",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image.src}
              alt={image.name}
              style={{ height: "300px", width: "100%", objectFit: "center" }}
            />
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                padding: "12px",
              }}
            >
              <h2 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {image.name}
              </h2>
              {image.startingWeight && image.currentWeight ? (
                <p style={{ fontSize: "0.875rem" }}>
                  Before : {image.startingWeight}, After : {image.currentWeight}
                </p>
              ) : (
                <p style={{ height: "1.5rem" }}></p>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
