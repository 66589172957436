import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesShadowCardWrapper,
  ServicesShadowCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "../Services/ServicesElements";
import Icon3 from "../../images/svg-3.svg";
import ProgressTrack from "../../images/progress_track.svg";
import FlexibleSchedule from "../../images/schedule.svg";
import Guidance from "../../images/Guidance.svg";
import { BsFillCartCheckFill } from "react-icons/bs";

function ChooseAdaptFit() {
  return (
    <ServicesContainer id="services">
      <ServicesH1>
        <p className="flex items-center justify-center text-black font-bold py-2 px-4 rounded-3xl mt-4">
          <BsFillCartCheckFill /> What You Get?
        </p>
      </ServicesH1>
      <ServicesShadowCardWrapper>
        <ServicesShadowCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Personalized Workouts</ServicesH2>
          <ServicesP>
            Tailored to your goals, fitness level, and schedule.
          </ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={ProgressTrack} />
          <ServicesH2>Nutrition Guidance</ServicesH2>
          <ServicesP>
            Easy-to-follow meal plans that fit into your lifestyle.
          </ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={FlexibleSchedule} />
          <ServicesH2>Flexible Scheduling</ServicesH2>
          <ServicesP>Continuous support from expert coaches</ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={Guidance} />
          <ServicesH2>One-on-One Coaching</ServicesH2>
          <ServicesP>
            Work out at home or the office, anytime that suits you
          </ServicesP>
        </ServicesShadowCard>
      </ServicesShadowCardWrapper>
    </ServicesContainer>
  );
}

export default ChooseAdaptFit;
