import React, { useState, useRef } from "react";
import Badges from "./Badges";

const TransformationCard = ({
  name,
  quote,
  startingWeight,
  currentWeight,
  timeTaken,
  src,
}) => {
  const maxLength = 150; // Adjust as needed for your application
  const [showFullQuote, setShowFullQuote] = useState(false);
  const contentRef = useRef(null);

  // Function to toggle showing full quote
  const toggleQuote = () => {
    setShowFullQuote((prevShowFullQuote) => !prevShowFullQuote);
  };

  // Function to truncate quote if longer than maxLength
  const truncateQuote = (quote) => {
    return quote.length > maxLength
      ? quote.substring(0, maxLength) + "..."
      : quote;
  };

  const hasBadges = startingWeight && currentWeight && timeTaken;
  const baseCardHeight = 550;
  const expandedCardHeight = 750;
  const imageHeight = 300;
  const badgesHeight = hasBadges ? 50 : 0; // Adjust this if Badges height changes
  const buttonHeight = 40; // Height of the button including padding
  const contentHeight =
    baseCardHeight - imageHeight - badgesHeight - buttonHeight;
  const expandedContentHeight =
    expandedCardHeight - imageHeight - badgesHeight - buttonHeight;

  // Initialize Google Analytics

  return (
    <div
      style={{
        width: "300px",
        height: showFullQuote ? expandedCardHeight : baseCardHeight,
        transition: "height 0.5s ease-in-out",
        marginBottom: "20px",
        position: "relative",
      }}
      className="bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <div>
        <img
          src={src}
          alt="After"
          style={{ height: "300px", width: "100%", objectFit: "cover" }}
        />
      </div>
      {hasBadges ? (
        <Badges
          transformations={{ startingWeight, currentWeight, timeTaken }}
        />
      ) : (
        <div style={{ height: "16px" }}></div> // Empty div to maintain spacing
      )}
      <div
        ref={contentRef}
        className="p-2"
        style={{
          overflowY: showFullQuote ? "auto" : "hidden",
          maxHeight: showFullQuote ? expandedContentHeight : contentHeight,
          transition: "max-height 0.5s ease-in-out",
          paddingBottom: buttonHeight, // Add padding to ensure the button doesn't overlap content
        }}
      >
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-2">{name}</h2>
          <p
            className={`text-gray-600 ${!showFullQuote ? "line-clamp-3" : ""}`}
          >
            {showFullQuote ? quote : truncateQuote(quote)}
          </p>
        </div>
      </div>
      {quote.length > maxLength && (
        <button
          onClick={toggleQuote}
          className="text-black font-bold rounded-md border border-gray-300 focus:outline-none shadow-md"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "2px 10px",
            zIndex: 10,
            background: "linear-gradient(to left,#f6f6f6, #EEEEEE)",
          }}
        >
          {showFullQuote ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default TransformationCard;
