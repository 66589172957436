import React from "react";
import Modal from "react-modal";
import { FaCheckCircle, FaTimes } from "react-icons/fa";

Modal.setAppElement("#root");

const SuccessModal = ({ modalIsOpen, setModalIsOpen }) => {
  const closeModal = () => {
    setModalIsOpen(false);
  };
  // Initialize Google Analytics

  return (
    <div className="flex justify-center items-center">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Success Modal"
      >
        <div className="relative p-6 bg-white rounded-lg text-center mx-4 sm:mx-0 max-w-sm sm:max-w-md md:max-w-lg">
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
          <FaCheckCircle
            style={{ width: "full", margin: "auto" }}
            className="text-green-500 text-5xl mb-4"
          />
          <h2 className="text-xl font-semibold text-gray-800">
            Thank you for reaching out to us!
          </h2>
          <p className="text-gray-600 mt-2">
            We will schedule your consultation within 24 hours
          </p>
        </div>
      </Modal>
    </div>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "0.5rem",
    border: "none",
    boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
  },
};

export default SuccessModal;
