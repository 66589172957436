import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation,useHistory } from 'react-router-dom';
import { RiUserAddLine } from "react-icons/ri";

const WhatsAppButton = () => {
  let location = useLocation();
  const history = useHistory();
  let currentPage = location.pathname.split('?')[0]
  const coachPage = currentPage === '/coach'
  const [isIconVisible, setIsIconVisible] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    const iconTimer = setTimeout(() => {
      setIsIconVisible(true);
    }, 200); // Delay before the icon appears

    const textTimer = setTimeout(() => {
      setIsTextVisible(true);
    }, 600); // Additional delay before the text appears

    return () => {
      clearTimeout(iconTimer);
      clearTimeout(textTimer);
    };
  }, []);

  const navigateToWhatsApp = () => {
    window.location.href =
      "https://wa.me/919004183595?text=I%20have%20a%20question%20and%20would%20like%20some%20information";
  };

  const navigateToEnroll = () => {
    history.push("/pricing");
  };

  return (
    <div
      onClick={coachPage ? navigateToEnroll : navigateToWhatsApp}
      style={{
        position: "fixed",
        bottom: "1.25rem", // bottom-5
        right: "1rem", // right-4
        padding: "0 1rem", // px-4
        height: "3.5rem", // h-16
        backgroundColor: "#22c55e", // bg-green-500
        borderRadius: "9999px", // rounded-full
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem", // gap-2
        boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)", // shadow-lg
        cursor: "pointer",
        transform: isIconVisible ? "translateX(0)" : "translateX(10rem)",
        transition: "transform 0.5s ease-in-out",
        opacity: isIconVisible ? 1 : 0,
        zIndex: 10,
      }}
    >
      {coachPage ?  <RiUserAddLine style={{ fontSize: "2rem", color: "black" }}/> : <FaWhatsapp style={{ fontSize: "2rem", color: "black" }} />}
      <h1
        style={{
          color: "black",
          opacity: isTextVisible ? 1 : 0,
          transition: "opacity 0.5s ease-in-out 0.2s",
        }}
      >
        {coachPage ? "Enroll" : "Let's Connect"}
      </h1>
    </div>
  );
};

export default WhatsAppButton;
