import React from "react";
import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import Icon3 from "../../images/svg-3.svg";
import Icon4 from "../../images/svg-4.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

function Services() {
  // Initialize Google Analytics

  return (
    <ServicesContainer id="services">
      <ServicesH1>How our plan works?</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Listen</ServicesH2>
          <ServicesP>
            We take the time to listen to your goals, concerns, and preferences,
            ensuring that your fitness journey aligns with your aspirations
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Evaluate</ServicesH2>
          <ServicesP>
            We assess your current fitness level, strengths, weaknesses, and any
            food intolerances and habits to create a plan that is safe and
            effective for you
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Personalised</ServicesH2>
          <ServicesP>
            We considers your fitness goals, dietary preferences, and workout
            style to ensure it's a perfect fit for your lifestyle.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Track</ServicesH2>
          <ServicesP>
            We monitor your progress through our mobile app and personal
            interactions, helping you stay on course towards your fitness goals
            by making adjustments as needed.{" "}
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
}

export default Services;
