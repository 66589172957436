export const reviewsRohit = [
    {
      id: 1,
      username: "Dhruv Jhaveri",
      rating: 5,
      review:
        "Working with Rohit Shahane has been a game-changer for me. His expertise in diet, weight loss, and muscle gain has been evident in every calls we have had and the plans he gas given me to follow. Not only have I seen significant progress in reaching my fitness goals, but I've also gained valuable knowledge about nutrition and exercise. Their personalized approach and unwavering support have made the journey enjoyable and sustainable. Highly recommend for anyone serious about transforming their health and physique!",
      photo:
        "https://lh3.googleusercontent.com/a-/ALV-UjWWedupcrkrb5PTITaFYVr6iawn90a2mjSMKFWboiB5ZG_-EdIH5Q=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/uemC5Wq",
    },
    {
      id: 2,
      username: "Swapnil Thakare",
      rating: 5,
      review:
        "I'm traning under Rohit Shahane sir. When i started my journey i was 116 KG but with sir's magic diet and workout plan i'm currently 94 KG and still working to get to my desired weight. Without a doubt you can enroll with Rohit sir and see transformation within yourself. My energy levels are high and i'm pretty much enjoying my diet as sir is very flexible with suggesting and tweaking diet plans to adjust the cravings.",
      photo:
        "https://lh3.googleusercontent.com/a-/ALV-UjUHMo0OOOXmr_Qq5W8w7d58PJMYwFyos-eBARYU9n8qL8m8EE-vbw=w132-h132-p-rp-mo-ba6-br100",
      ratingLink: "https://g.co/kgs/8j7EkVJ",
    },
    {
      id: 3,
      username: "Deepak gupta",
      rating: 5,
      review:
        "Rohit sir has been helping out on my journey to be fit for a long time. He has been a constant guide on exercise and nutrition part. Also keep a constant check on your health. He is a mentor and a family to guide you adapt better lifestyle. His technique of fitness is absolutely amazing.",
      photo:
        "https://lh3.googleusercontent.com/a-/ALV-UjWNOpG7N-M9pT713TuV2JXymXv2m-yPzdHvD3kCpD733ZTlgSWP=w132-h132-p-rp-mo-ba3-br100",
      ratingLink: "https://g.co/kgs/g4mPr3E",
    },
    {
      id: 4,
      username: "Devansh Vithlani",
      rating: 5,
      review:
        "I've been with Rohit Sir for about 3 years now and the dramatic differences he has bought to my dietary and social life would be next to impossible without him. He tries to modify your lifestyle without making it seem like he is changing much. He has always been checking on multiple times a week. He is a professional and I have experienced it since day 1.",
      photo:
        "https://lh3.googleusercontent.com/a-/ALV-UjVJPG-FyivMP3l0jpTdll1O1UwLpIUvX927pTeaGL3ASs-Jztht=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/cgJ9nze",
    },
    {
      id: 5,
      username: "Karishma Jain",
      rating: 5,
      review:
        "Hi!I am taking session with Mr. Rohit Sahane. And he is amazing and have lot of experience. I have seen and I am seeing changes in me.I am really Thankful to him for helping me out in my transformation journey.",
      photo:
        "https://lh3.googleusercontent.com/a/ACg8ocIiMind8BrYtfoEXY1Twt8_RF4w7Mk2SIYy5SN2xQUVlS-ANw=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/rnE4Czt",
    },
    {
      id: 6,
      username: "Dharmendra Rajawat",
      rating: 5,
      review:
        "I would like to extend heartfelt thanks and gratitude to dear Rohit. Your reasearched , methodical and systematic guidance and dedication has been invaluable in transforming my journey from 93 Kg to 75 Kg weight in six months time while maintaining and building muscle mass. Your push and motivation even today inspires me to continue on this journey ahead..Awesome & stupendous 👍👍🙏🙏",
      photo:
        "https://lh3.googleusercontent.com/a/ACg8ocIwvbwYKsmZB5zAcdwihCcinXRXGhRsfqSuM47yVUSEPyZiog=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/v77H8Dv",
    },
    {
      id: 7,
      username: "Dhangar Vishal",
      rating: 5,
      review:
        "Hi, have been guided by Rohit on my fitness journey for almost a year now. He has been great support in this whole journey by providing excellent guidance on diet and exercises. Always kept track on my progress and guided me through out the process. Would recommend him for fitness journey. He is excellent with his knowledge and support.",
      photo:
        "https://lh3.googleusercontent.com/a/ACg8ocImUgw3ojFACsHbeIhg24HIBQOFzB3nujR8TXYSUD5qeAln6g=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/Qi6WT7y",
    },
    {
      id: 8,
      username: "Rahul Wadhwa",
      rating: 5,
      review:
        "Coach Rohit helped me get back to my weight loss goals.He was very patient with me and provided support to boost my physical and mental health.His diets are really effective and if you follow it to the point results can be seen.Thank you Coach Rohit.",
      photo:
        "https://lh3.googleusercontent.com/a/ACg8ocJoBAy7Iz4HqXl3JBYcAX0zrNufMUHS8ONKm6-N2agqVgTFxg=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/SbRVR3D",
    },
    {
      id: 9,
      username: "Pradip Lokhande",
      rating: 5,
      review:
        "I have been with Rohit(Dushyant)sir from last 1.5 years. He is one of the gem of a persons I met with.His brilliance in diet patterns and exercise patterns helped me to get on track with my healthy lifestyle and weight loss journey. His knowledge in diet building is tremendous and easy to follow. I am happy that I am with right mentor cum best friend in my weight loss journey.",
      photo:
        "https://lh3.googleusercontent.com/a-/ALV-UjUCPaWPOu1thECCBwk59TlHlyo0y4in8jm5es9a-fkmxRU_5zom=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/F1cmfqB",
    },
    {
      id: 10,
      username: "siva kumar",
      rating: 5,
      review:
        "I have never seen coach like Rohit he was best Coach. He take cares personally like family member throughout the weight loss process. I really appreciate overwhelmed that you showed care during the process. Who was planning to weight loss/ gain weight I highly recommend Rohit. Thank you very much Rohit.",
      photo:
        "https://lh3.googleusercontent.com/a/ACg8ocKw_Bq2dVF3g9cv0C2I1S7Ms1RKzsV-hHzP1xK3a-onWVylmQ=w132-h132-p-rp-mo-br100",
      ratingLink: "https://g.co/kgs/amTh4XE",
    },
];