// src/components/Badges.js
import React from "react";
import TransformationBadge from "./TransformationBadge";

const Badges = ({ transformations }) => {
  console.log("badges", transformations.startingWeight);
  return (
    <div className="flex flex-wrap justify-center items-center">
      <TransformationBadge
        startingWeight={transformations.startingWeight}
        currentWeight={transformations.currentWeight}
        timeTaken={transformations.timeTaken}
      />
    </div>
  );
};

export default Badges;
