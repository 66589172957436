import React, { useEffect } from "react";

const GETFAQ = () => {
  const toggleClass = (questionNumber) => {
    const answers = document.querySelectorAll("#answer");
    answers[questionNumber].classList.toggle("hidden");
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Frequently Asked Questions
            </h2>
            <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Find answers to questions we always receive.If your query is not
              there, send us a message! We’re here to help.
            </p>
          </div>

          <div class="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(0)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  What happens during the free consultation?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400 rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" className="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  During the consultation, we’ll discuss your fitness goals,
                  lifestyle, and any challenges you face in staying active.
                  Based on this, we’ll design a personalized fitness plan
                  tailored specifically to you. It’s a no-obligation session to
                  see if AdaptFit is the right fit for you.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(1)}
              >
                <span class="flex sm:w-72 sm:p-2 text-lg font-semibold text-black">
                  How long will it take to see results?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Results vary depending on individual goals and commitment.
                  However, many clients start seeing visible changes within the
                  first 4 weeks. We track your progress closely and adjust your
                  plan constantly as needed to ensure progressive results.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <div class="">
                <button
                  type="button"
                  class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                  onClick={() => toggleClass(2)}
                >
                  <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                    What if I don’t have a lot of time to work out?
                  </span>

                  <svg
                    class="w-6 h-6 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                  <p>
                    We understand busy schedules! AdaptFit’s programs are
                    designed for professionals like you, with workouts that
                    range from just 15 to 30 minutes a day. We help you make the
                    most of your available time to achieve your fitness goals.
                  </p>
                </div>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(3)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Can I follow the meal plans if I have dietary restrictions?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Absolutely! Our meal plans are fully customizable to fit your
                  dietary needs, whether you’re vegetarian, vegan, gluten-free,
                  or have other restrictions. We’ll work with you to create
                  meals that are healthy and easy to prepare
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(4)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Is the program suitable for beginners?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  Yes! Whether you’re new to fitness or experienced, we tailor
                  the workouts to your fitness level. We’ll start at a pace
                  that’s comfortable for you and increase intensity as you
                  progress.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(5)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  Do I need to visit a gym to follow this program?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  No, our programs can be done from the comfort of your home. If
                  you prefer going to the gym, we can also customize the
                  workouts for a gym environment.
                </p>
              </div>
            </div>

            <div class="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleClass(6)}
              >
                <span class="flex text-lg sm:w-72 sm:p-2 font-semibold text-black">
                  What if it’s not right for me?
                </span>

                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div id="answer" class="hidden px-4 pb-5 sm:px-6 sm:pb-6">
                <p>
                  No problem! If you don’t see results in 30 days, we’ll refund
                  your money—no questions asked.
                </p>
              </div>
            </div>

            <p class="text-center text-gray-600 textbase mt-9">
              Didn’t find the answer you are looking for?
              <a
                href="https://wa.me/919004183595?text=Hey%2C%20I'd%20love%20to%20join%20you%20on%20this%20fitness%20journey!"
                title=""
                class="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
              >
                Contact Us
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default GETFAQ;
