import React from "react";

import { homeObjOne, homeObjTwo } from "../components/InfoSection/Data";
import { reviews } from "../components/reviewsData";

import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import Services from "../components/Services";
import RatingsReviewsCarousel from "../components/ReviewsCarousel";
import Carousel from "../components/Carousel";
import { MdOutlinePublishedWithChanges, MdStar } from "react-icons/md";
import StatsComponent from "../components/StatsComponent";
import MobileApp from "../components/MobileApp";
// import useGoogleAnalytics from "../components/googleanalytics";

function Home() {
  const phases = [
    {
      phase: "Phase 1",
      weeks: "Week 1-2",
      details: [
        "Day 1 - Receive your transformation plan and begin your journey!",
        "Share your exercise techniques and meals regularly for feedback.",
        "Discuss any questions you have about exercises, diet, mindset, or routine.",
      ],
    },
    {
      phase: "Phase 2",
      weeks: "Week 2-6",
      details: [
        "Training updates: customising exercises after reviewing phase 1 and targeting specific muscles.",
        "Continue to share your workout forms and ask questions.",
        "You’ll see progress: losing fat, feeling more energetic, and having a better mood and sleep.",
      ],
    },
    {
      phase: "Phase 3",
      weeks: "Weeks 6-10",
      details: [
        "Updated training plan with more exercises",
        "Noticeable strength increases in all major lifts",
        "Visible progress: dropping inches, leaner face and belly",
      ],
    },
    {
      phase: "Phase 4",
      weeks: "Week 10-14",
      details: [
        "Training for overall development considering muscle imbalances, weak points, injury",
        "Fitness is now a part of your daily life.",
        "Others are noticing your progress, complimenting you, and asking, 'What's your diet?'",
      ],
    },
    {
      phase: "Phase 5",
      weeks: "Week 14-22",
      details: [
        "Advancing the training to next level",
        "You feel completely confident in the gym.",
        "Your body shows your progress before you even speak.",
      ],
    },
    {
      phase: "Phase 6",
      weeks: "Week 22-26",
      details: [
        "Understanding each and every detail about how to adjust training according to lifestyle",
        "You know more about fitness than 90% of people do.",
        "Your body has transformed and is unrecognizable from when you started!",
        "So, let me ask again – Are you ready for serious progress?",
      ],
    },
  ];

  const PlanSection = () => {
    // Initialize Google Analytics

    return (
      <div className="flex justify-center items-center gap-4 p-2 flex-wrap">
        {phases.map((phase, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:-translate-y-1 hover:scale-105 duration-300 w-96 sm:w-72 h-80 md:h-full"
          >
            <h4 className="text-md uppercase font-bold text-green-500 mb-2">
              {phase.weeks}
            </h4>
            <h2 className="text-2xl font-bold text-gray-600 mb-2">
              {phase.phase}
            </h2>
            <ul className="list-disc pl-6 space-y-1 text-gray-700">
              {phase.details.map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  // Initialize Google Analytics

  return (
    <>
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <Services />
      <InfoSection {...homeObjTwo} />
      <div
        id="get-started"
        className="flex justify-center items-center font-bold m-5"
      >
        <h1 className="bg-black text-white font-extrabold uppercase px-6 py-2 text-2xl md:text-xl text-center m-3 flex items-center gap-2 rounded-xl">
          Journey to better self
        </h1>
      </div>
      <PlanSection />
      <a
        href="https://g.co/kgs/K6GYwe2"
        className="flex justify-center items-center font-bold m-5"
      >
        <h1 className="bg-black text-white px-6 py-2 rounded-xl uppercase text-2xl md:text-xl text-center flex items-center gap-2">
          <MdStar style={{ display: "inline" }} />
          Ratings & Reviews
        </h1>
      </a>
      <RatingsReviewsCarousel reviews={reviews} />
      <div className="flex justify-center items-center font-bold m-5">
        <h1 className="bg-black text-white font-extrabold uppercase px-6 py-2 text-2xl md:text-xl text-center m-3 flex items-center gap-2 rounded-xl">
          <MdOutlinePublishedWithChanges />
          Transformations
        </h1>
      </div>
      <Carousel />
      <StatsComponent />
      <MobileApp />
      <div className="mb-4" />
    </>
  );
}

export default Home;
