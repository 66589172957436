import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./NavbarElements";
import logo from "../../images/logo.png";

function Navbar({ toggle }) {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  if (window.location.pathname === "/") {
    window.addEventListener("scroll", changeNav);
  }

  const scrollProps = {
    activeClass: "border-solid border-b-4 border-green-500",
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -80,
  };
  // Initialize Google Analytics

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img alt="logo" src={logo} height={120} width={120} />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="/gettingstarted" {...scrollProps}>
                <p style={{ background: "linear-gradient(to left,#1cae6e, #007542)" }} className="font-bold p-2 bg-green-500 rounded-md">
                  Free Consult
                </p>
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to="/" {...scrollProps}>
                Home
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/contact" {...scrollProps}>
                Contact Us
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/coaches" {...scrollProps}>
                Coaches
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/pricing" {...scrollProps}>
                Enroll Now
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
