import React, { useEffect, useState } from "react";
import {
  ArrowForward,
  ArrowRight,
} from "../components/HeroSection/HeroElements";
import { Button } from "../components/ButtonElements";
import { useHistory } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover((prev) => !prev);
  };

  // Initialize Google Analytics

  return (
    <>
      <h1
        style={{
          backgroundImage: "url('/terms.png')",
        }}
        className="bg-cover font-bold h-40 bg-no-repeat bg-center text-white flex justify-center p-5 text-6xl uppercase items-center tracking-wider text-center md:text-4xl"
      >
        Terms and Conditions
      </h1>
      <div className="m-5 p-10 border-r-2 shadow-md text-justify">
        Welcome to Adaptfit hereby addressed as the “Company” and/or “Adaptfit”
        This document is an electronic record in terms of Information Technology
        Act, 2000 and published in accordance with the provisions of Rule 3 of
        the Information Technology (Intermediaries guidelines) Rules, 2011 that
        require publishing the Rules and Regulations, Privacy Policy and Terms
        and Conditions for access or usage of this Website –
        https://www.adaptfit.inability (hereinafter referred to as “Website”)
        and our related Website, Application, Services, Products and content
        (together referred to as “Services”). AGREEMENT TO TERMS User agreement
        : These Terms and Conditions (“Terms”)are agreed upon as of today
        between you (“You”) an individual or entity residing anywhere in the
        world and Adaptfit to govern your engagement with an usage of Adaptfit
        nutrition and exercise advice provided through mobile application or
        website through the pc , mobile or any other device/platform as well as
        any other media form, media channel, mobile website or mobile
        application related, linked, or otherwise connected thereto. Adaptfit
        may have subsidiaries and affiliated legal entities around the world
        (“Subsidiaries and Affiliates”), providing the Services to you on behalf
        of Adaptfit. <br />
        You acknowledge and agree that the Subsidiaries and Affiliates will be
        entitled to provide the Services to you under the terms of this
        Agreement. You agree that by accessing the Website and/or Mobile
        Application, you have read, understood, and agree to be bound by all of
        these Terms and Conditions. IF YOU DO NOT AGREE WITH ALL OR ANY OF THESE
        TERMS AND CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
        WEBSITE AND MOBILE APPLICATION AND YOU MUST DISCONTINUE THEIR USE
        IMMEDIATELY. PLEASE REVIEW THE FOLLOWING USER AGREEMENT CAREFULLY BEFORE
        USING THIS WEBSITE By clicking on “Accept” you acknowledge that you have
        read and fully agree to and accept the Terms of Use, Privacy Policy, and
        Disclaimer statements of adaptfit. Furthermore, by clicking “accept” you
        acknowledge that you agree to the following: 1. By entering this website
        or purchasing our programs/ services, you are agreeing to accept all
        parts of this disclaimer. Thus, if you do not agree to the disclaimer
        below, STOP now, and do not use our website, programs, services, or
        products. 3. Always consult your physician or healthcare provider before
        beginning any nutrition or exercise program. You should be in good
        physical condition and be able to participate in the exercise. adaptfit
        trainers are not licensed medical care provider and represent that they
        have no expertise in diagnosing, examining, or treating medical
        conditions of any kind, or in determining the effect of any specific
        exercise on a medical condition. 5. You should understand that when
        participating in any exercise or exercise program, there is the
        possibility of physical injury. If you engage in this exercise or
        exercise program, you agree that you do so at your own risk, are
        voluntarily participating in these activities, assume all risk of injury
        to yourself, and agree to release and discharge adaptfit from any and
        all claims or causes of action. 6. Exercise is not without its risks and
        this or any other exercise program may result in injury. As with any
        exercise program, if at any point during your workout you begin to feel
        faint, dizzy or have physical discomfort, you should stop immediately
        and consult a medical professional. 7. We make no guarantees concerning
        the level of success you may experience, and you accept the risk that
        results will differ for each individual. 8. The testimonials and
        examples provided are exceptional results, which do not apply to the
        average purchaser and are not intended to represent or guarantee that
        anyone will achieve the same or similar results. 9. Each individual’s
        health, fitness, and nutrition success depends on his or her background,
        dedication, desire, and motivation. As with any health-related program
        or service, your results may vary, and will be based on many variables,
        including but not limited to, your individual capacity, life experience,
        unique health and genetic profile, starting point, expertise, and level
        of commitment. 10. All plans are prepared on the basis of the
        information provided by you. You are responsible for the accuracy of any
        information that you provide to us. You are responsible for informing us
        of any health issues or medical conditions when asking us to prepare a
        plan 11. You hereby acknowledge and agree that you are physically and
        mentally sound and currently have no physical conditions that would be
        aggravated by your involvement in an exercise program, nutrition plan or
        exercise training session and that you have permission from a licensed
        physician that you are able to undertake health, fitness, and/or
        performance training program and/or to follow a nutrition plan.
        Eligibility: You must be at least 18 to enroll under a coach on the
        Platform. If you are between the ages of 13-18, you may use our other
        free services (excluding registering for a Coach) (“Paid Services”) only
        with the supervision and consent of a parent or guardian. No individual
        under these age limits may use the Paid Services, provide any Personal
        Data to adaptfit, or otherwise submit Personal Data through the Services
        (e.g., a name, address, telephone number, or email address). HEALTH
        DISCLAIMER : In consideration of being allowed to participate in the
        activities and structured diet programs offered by adaptfit and to use
        its structured diet programs and training schedule, in addition to the
        payment of any fee or charge, you do hereby waive, release and forever
        discharge and hold harmless adaptfit and its coaches, consultants,
        officers, agents, and employees from any and all responsibility,
        liability, cost and expenses, including injuries or damages, resulting
        from your participation in any activities, or your use of any structured
        diet or training programs. You do also hereby release adaptfit, its
        coaches, consultants, officers, agents and employees from any
        responsibility or liability for any injury, damage or disorder
        (physical, metabolic, or otherwise) to you, or in any way arising out of
        or connected with your participation in any activities with adaptfit.
        You understand that fitness activities involve a risk of loss of
        personal property, serious injury and even death, and that you are
        voluntarily participating in these activities and using equipment and
        machinery with knowledge of the risk involved. You hereby agree to
        expressly assume and accept any and all risks of loss of personal
        property, serious injury or death related to said fitness activities. In
        addition, You certify that you are 18 years of age or older. You do
        hereby further declare yourself to be physically sound and suffering
        from no condition, impairment, disease, infirmity or other illness that
        would affect nutrient metabolism or prevent your participation or use of
        equipment or machinery except as hereinafter stated. You also agree that
        if you are suffering from any ailment or any medical condition you have
        to inform and produce relevant documents to adaptfit before beginning
        any program, physical activities or any other plans that may be
        referenced, discussed or offered under the Services. You do hereby
        acknowledge that you have a physician’s approval for your participation
        in an exercise/fitness activity or in the use of exercise equipment and
        machinery. You also acknowledge that you have regular physical
        examination and consultation with your physician as to physical
        activity, exercise and use of exercise and training equipment so that
        you might have his/her recommendations concerning these fitness
        activities and use of equipment. You acknowledge that you have either
        had a physical examination and been given your physician’s permission to
        participate, or that you have decided to participate in activity and use
        of equipment, machinery, and programs designed by adaptfit without the
        approval of your physician and do hereby assume all responsibility for
        your participation and activities, and utilization of equipment and
        machinery in your activities. adaptfit DOES NOT PROVIDE PROFESSIONAL
        MEDICAL SERVICES OR ADVICE. THE SERVICES PROVIDED BY THE
        COACHES/TRAINING SPECIALISTS AND AVAILABLE ON THE WEBSITE AND/OR MOBILE
        APP DO NOT CONTAIN OR CONSTITUTE, AND SHOULD NOT BE INTERPRETED AS
        MEDICAL ADVICE OR OPINION. NO DOCTOR-PATIENT RELATIONSHIP IS CREATED.
        USE OF THE SERVICES IS NOT FOR MEDICAL EMERGENCIES. IF YOU THINK YOU
        HAVE A MEDICAL EMERGENCY, CONSULT YOUR DOCTOR. The diet/training
        programs provided by the coaches/training specialists and available on
        our website are not meant to treat or manage any health condition.
        Always consult with your healthcare provider prior to adjusting your
        current style of eating or beginning any new diet and/or training plan.
        You understand that you are agreeing to the terms of the Company i.e
        adaptfit having known that beforehand and understand that not following
        the instructions and structured diet programs entirely and regularly
        will not produce 100% results. USER REPRESENTATIONS By using the
        Website/Mobile Application, you represent and warrant that: * All
        registration information you submit will be true, accurate, current, and
        complete. You will maintain the accuracy of such information and
        promptly update such registration information as necessary. * You have
        the legal capacity and you agree to comply with these Terms and
        Conditions. -You are not a minor in the jurisdiction in which you
        reside. * You will not access the Website/Mobile Application through
        automated or non-human means, whether through a bot, script or
        otherwise. * You will not use the Website/Mobile Application for any
        illegal or unauthorized purpose; and your use of the Website/Mobile
        Application will not violate any applicable law or regulation. If you
        provide any information that is untrue, inaccurate, not current, or
        incomplete, we have the right to suspend or terminate your account and
        refuse any and all current or future use of the Website/Mobile
        Application (or any portion thereof). USER REGISTRATION You may be
        required to register with the Website/Mobile Application. You agree to
        keep your password confidential and will be responsible for all use of
        your account and password. We reserve the right to remove, reclaim, or
        change a username you select if we determine, in our sole discretion,
        that such username is inappropriate, obscene, or otherwise
        objectionable. INTELLECTUAL PROPERTY RIGHTS adaptfit is a registered
        Trademark . Unless otherwise indicated, the Website/Mobile Application
        is our proprietary property and all content, source code, databases,
        functionality, software, website designs, audio, video, text, images,
        photographs, graphics, illustrations, questions, creative suggestions,
        messages, comments, feedback, ideas, recipes, notes, drawings, articles
        and other materials, including nutritional information contributed to
        the Food Database on the Website/Mobile Application (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of India,
        foreign jurisdictions, and international conventions. The Content and
        the Marks are provided on the Website/Mobile Application “AS IS” for
        your information and personal use only. Except as expressly provided in
        these Terms and Conditions, no part of the Website/Mobile Application
        and no Content or Marks may be modified, copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed performed, encoded,
        translated, transmitted, distributed, sold, licensed, create derivative
        works of or otherwise exploited for any commercial purpose whatsoever in
        whole or in part without our express prior written permission. You may
        not modify, publish, transmit, distribute, perform, participate in the
        transfer or sale, create derivative works of, or in any way exploit, any
        of the Content, in whole or in part. When Content is downloaded to your
        computer, phone, tablet or any other mobile device, you do not obtain
        any ownership interest in such Content. Modification of the Content or
        use of the Content for any other purpose, including, but not limited to,
        use of any Content in printed form or on any other applications or
        networked computer environment is strictly prohibited unless you receive
        our prior written consent. FEES AND PAYMENT We accept the following
        forms of payment. – Credit Card – Debit Card – Net Banking – Mobile
        Wallets – UPI – Paypal You may be required to purchase or pay a fee to
        access some of our services. You agree to provide current, complete, and
        accurate purchase and account information for all purchases made via the
        Website/Mobile Application. You further agree to promptly update account
        and payment information, including email address, payment method, and
        payment card expiration date, so that we can complete your transactions
        and contact you as needed. We bill you through an online billing account
        for purchases made via the Website/Mobile Application. GST will be added
        to the price of purchases as deemed required by us. We may change prices
        at any time. All payments shall be in Indian National Rupees. You agree
        to pay all charges or fees at the prices then in effect for your
        purchases, and you authorize us to charge your chosen payment provider
        for any such amounts upon making your purchase. We reserve the right to
        correct any errors or mistakes in pricing, even if we have already
        requested or received payment. We also reserve the right to refuse any
        order placed through the WEBSITE/MOBILE APPLICATION.
        <br />
        <br />
        <b>CANCELLATION /REFUND policy:</b> All purchases made online/ offline
        at adaptfit website are <b>NON- REFUNDABLE</b> except for the 30 day
        Money Back offer. If you are unsatisfied with our services, please email
        us at adaptfit.in@gmail.com COMMUNITY STANDARDS AND CONDUCT GUIDELINES
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavours except those that are
        specifically endorsed or approved by us. Your privilege to use the
        Services (including your ability to contribute to discussions on the
        Public Forum or communicate with coaches and/or other users on adaptfit
        depends on your compliance with the community standards and conduct
        guidelines. We may revoke your privileges to use all or a portion of the
        Services and/or take any other appropriate measures to enforce these
        community standards and conduct guidelines if violations are brought to
        our attention. Further, if you fail to adhere to our community standards
        and conduct guidelines, or any part of these Terms & Conditions, we may
        terminate, in our sole discretion, your use of, or participation in, any
        Public Forum or the Services. Any violation of this section may subject
        you to civil and/or criminal liability. YOU AGREE AND UNDERSTAND THAT
        YOU MAY BE HELD LEGALLY RESPONSIBLE FOR DAMAGES SUFFERED BY OTHER
        MEMBERS OR THIRD PARTIES AS THE RESULT OF YOUR REMARKS, INFORMATION,
        FEEDBACK OR OTHER CONTENT POSTED OR MADE AVAILABLE ON THE SERVICES
        (INCLUDING ANY FORUM) THAT IS DEEMED DEFAMATORY OR OTHERWISE LEGALLY
        ACTIONABLE. UNDER SECTION 79 OF THE INFORMATION TECHNOLOGY AMENDMENT
        ACT, 2008, adaptfit IS NOT LEGALLY RESPONSIBLE, NOR CAN IT BE HELD
        LIABLE FOR DAMAGES OF ANY KIND, ARISING OUT OF OR IN CONNECTION TO ANY
        DEFAMATORY OR OTHERWISE LEGALLY ACTIONABLE REMARKS, INFORMATION,
        FEEDBACK OR OTHER CONTENT POSTED OR MADE AVAILABLE ON THE SERVICES.
        <br />
        <b>Shipping</b>
        <br />
        Please note that our services are entirely online and do not involve any
        physical products. All our offerings are delivered digitally, ensuring
        prompt and efficient service delivery.
        <br />
        <b>Location</b>
        <br />
        Santacruz West, Juhu tara road,
        <br /> Near SBI Bank,400049
        <br /> Maharashtra,Mumbai
        <br />
        <div className="m-4">
          <Button
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            onClick={() => history.push("/")}
          >
            Accept & Go Back {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Terms;
